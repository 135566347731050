import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { signIn, logOut } from '../../api/auth';
import { notify } from '../../utils/notify';
import { COUNTRY_NOT_ALLOWED } from '../../constants/errorKeys';
import { checkIpCountry as checkIpCountryRequest } from 'api/country';
const token = sessionStorage.getItem('token') || localStorage.getItem('token');

export const logout = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
  try {
    const response = await logOut();
    if (response.status === 200) {
      localStorage.removeItem('token');
      localStorage.clear();
    }

    // return response
  } catch (error) {
    notify({
      type: 'error',
      message: error.response.data.message,
      timeOut: 3000,
    });
    return rejectWithValue(error.response.data.message);
  }
});

export const login = createAsyncThunk('auth/login', async (creds, { rejectWithValue }) => {
  try {
    const response = await signIn(creds);
    if (response.status === 200) {
      localStorage.setItem('token', response.data.token);
    }

    return response.data.token;
  } catch (error) {
    const interceptorException = error.response.data.message === COUNTRY_NOT_ALLOWED;
    if (!interceptorException) {
      notify({
        type: 'error',
        message: error.response,
        timeOut: 3000,
      });
    }
    return rejectWithValue(error.response.data.message);
  }
});

export const checkIpCountry = createAsyncThunk(
  'auth/checkIpCountry',
  async (_, { rejectWithValue }) => {
    try {
      const response = await checkIpCountryRequest();

      const isBlockedIp = response.data['BLOCKED'] === 1;

      return isBlockedIp;
    } catch (error) {
      notify({
        type: 'error',
        message: error.response.data['MESSAGE'],
        timeOut: 3000,
      });
      return rejectWithValue(error.response.data['MESSAGE']);
    }
  },
);

const initialState = {
  token: null,
  isAuthorized: !!token,
  isBlockedIpCountry: false,
  isLoading: false,
  isError: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    //login
    [login.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isAuthorized = true;
      state.token = action.payload;
    },
    [login.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.payload;
    },

    //logout
    [logout.pending]: () => initialState,
    [logout.fulfilled]: (state) => {
      state.isAuthorized = false;
    },
    [logout.rejected]: () => initialState,

    //check ip
    [checkIpCountry.pending]: (state) => {
      state.isLoading = true;
    },
    [checkIpCountry.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isBlockedIpCountry = action.payload;
    },
    [checkIpCountry.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.payload;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
