import React from "react";
import { RefObject } from "react";
import { ContextMenu } from "../../../../../components/ui/ContextMenu/ContextMenu";
import { ChatMessageType } from "../../../../../types/Chat";
import { ReplyMessageOption } from "../../menuOptions/ReplyMessageOption";
import { CopyTextOption } from "../../menuOptions/CopyTextOption";

type MessageContextMenuProps = {
    message: ChatMessageType;
    menuRef: RefObject<HTMLDivElement>;
    isToggled: boolean;
    points: { x: number, y: number };
};

export const MessageContextMenu = ({ message, menuRef, isToggled, points }: MessageContextMenuProps) => {
    return (
        <>
            <ContextMenu
                contextMenuRef={menuRef}
                isToggled={isToggled}
                positionX={points.x}
                positionY={points.y}
                className='message-menu'
            >
                <ReplyMessageOption message={message}/>
                <CopyTextOption text={message?.body}/>
            </ContextMenu>
        </>
    )
}