import React from "react";
import { selectThread } from "../../../../store/slice/chat/chatSlice";
import { useAppSelector } from "../../../../store";
import { ChatMessageForm } from "../ChatMessageForm/ChatMessageForm";
import { MessagesContainer } from "./components/MessagesContainer";
import { handleReadThread } from "../../helpers/readThread";
import './ChatDialog.scss';

export const ChatDialog = () => {
    const support = useAppSelector(selectThread);

    return (
        <div className="chat-dialog-page" onClick={() => handleReadThread(support)}>
            <div className="dialog-header">Live Support Chat</div>
            <MessagesContainer />
            {support ? <ChatMessageForm /> : null}
        </div>
    )
}