import React from "react";
import cn from "classnames";
import { X } from "phosphor-react";
import { ChatMessageType, ProcessedMessageMode } from "../../../../types/Chat";
import { selectChatUserId, setProcessedMessage } from "../../../../store/slice/chat/chatSlice";
import { renderDangerHTML } from "../RenderDangerHtml/RenderDangerHtml";
import { useAppDispatch, useAppSelector } from "../../../../store";
import './ChatFormProcessedMessage.scss';

type ChatFormProcessedMessageProps = {
    message: ChatMessageType;
    mode: ProcessedMessageMode;
};

export const ChatFormProcessedMessage = ({ message, mode }: ChatFormProcessedMessageProps) => {
    const dispatch = useAppDispatch();
    const userId = useAppSelector(selectChatUserId);

    const handleClose = () => {
        dispatch(setProcessedMessage({ mode: null, message: null }));
    };

    const isReplying = mode === ProcessedMessageMode.IS_REPLYING;

    return (
        <div className={cn("chat-processed", {isReplying})}>
            <div className="chat-processed-title">
                {isReplying ? (
                    <>
                        Reply
                        <span>{message?.owner_id === userId ? message?.owner?.name : 'Support'}</span>
                    </>
                ) : null}
            </div>
            {renderDangerHTML(message?.body, 'chat-processed-text')}
            <div className="chat-processed-close" onClick={handleClose}><X size={32} color={'#722ed1'}/></div>
        </div>
    )
}