import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { COUNTRY_NOT_ALLOWED } from '../constants/errorKeys';
import { notify } from './notify';

export const useRegionValidation = () => {
    const intl = useIntl();
    const prohibitedCountry = sessionStorage.getItem(COUNTRY_NOT_ALLOWED);

    useEffect(() => {
        if (prohibitedCountry) {
            notify({
                type: 'error',
                message: intl.formatMessage({
                    id: 'auth.signUp.notAllowed',
                    defaultMessage: 'Access is blocked due to the region where you are located. Please contact customer support.',
                }),
                timeOut: 10000,
            });
            sessionStorage.removeItem(COUNTRY_NOT_ALLOWED);
        }
    }, [prohibitedCountry]);
}