import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './variables.css';
import './globalStyle.scss';
import './reload-button.scss'
import App from './App';
import SvgSprite from './SvgSprite';
import {NotificationContainer} from 'react-notifications';
import { Provider } from 'react-redux';
import { store } from './store';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
        <SvgSprite />
        <NotificationContainer/>
      </BrowserRouter>
    </React.StrictMode>
    </Provider>,
  document.getElementById('root')
);
