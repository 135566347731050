import React, { FC, useEffect, useState } from 'react';
import UserDashboard from './UserDashboard/UserDashboard';
import Menu from '../../components/ui/Menu';

import './dashboarLayout.scss';
import Header from '../../components/ui/Header';
import { useWindowWidth } from '@react-hook/window-size';
import { MAIN_MENU_TYPE } from '../../constants/menuItems';
import { MOBILE_SIZE_WIDTH } from '../../constants/mobile';
import { useHeartBeat } from "../Chat/helpers/useHeartBeat";
import { useAppDispatch } from "../../store";
import { chatInit } from "../../store/slice/chat/chatSlice";

export const DashboardLayout: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const width: number = useWindowWidth();
  const [typeMenu, setTypeMenu] = useState(MAIN_MENU_TYPE.SIDE_MENU_SEMI_BIG);

  const isMobile = (): boolean => width < MOBILE_SIZE_WIDTH;

  const onHover = (): void => {
    setTypeMenu(MAIN_MENU_TYPE.SIDE_MENU);
  };

  const onUnHover = async (): Promise<void> => {
    setTypeMenu(MAIN_MENU_TYPE.SIDE_MENU_SEMI_BIG);
  };

  useEffect(() => {
    dispatch(chatInit());
  }, []);

  useHeartBeat();

  return (
    <div className={`dashboard ${isMobile() ? 'mobile' : ''}`}>
      <UserDashboard>
        {!isMobile() ? (
          <div
            onMouseOver={onHover} onMouseLeave={onUnHover}
            style={{ width: typeMenu === MAIN_MENU_TYPE.SIDE_MENU_SEMI_BIG ? 70 : 270 }}
            className={'dashboard__sidebar'}
          >
            <Menu type={typeMenu} />
          </div>
        ) : (
          ''
        )}
        <div
          style={{ paddingLeft: typeMenu === MAIN_MENU_TYPE.SIDE_MENU_SEMI_BIG ? 70 : 270 }}
          className={'dashboard__content'}
        >
          <Header typeMenu={typeMenu} isMobile={isMobile()} />
          <div className={'dashboard__content-wrap'}>{children}</div>
        </div>
      </UserDashboard>
    </div>
  );
};
