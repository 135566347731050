import React from 'react';
import './numberInput.scss';
import { useController } from 'react-hook-form';
import Tooltip from '../Tooltip/Tooltip';
import NumberFormat from 'react-number-format';

const NumberInput = (props) => {
  const { field } = useController(props);
  return (
    <div className={'number__input'}>
      <div className={'label-wrap'}>
        {props.label && (
          <label htmlFor={props.id} className={props.errors ? 'label error' : 'label'}>
            {props.label}
            {props.rules && props.rules.required ? <span className={'required'}>*</span> : ''}
          </label>
        )}
        {props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}
      </div>
      <div className={props.errors ? 'input-wrap error' : 'input-wrap'}>
        <NumberFormat
          {...field}
          format={props.format}
          mask={props.mask}
          className={props.errors ? 'input error' : 'input'}
          disabled={props.disabled}
        />
        {props.errors && <p className={'errorName'}>{props.errors.message}</p>}
      </div>
    </div>
  );
};

export default NumberInput;
