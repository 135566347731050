import React from "react";
import { ReactNode } from "react";
import './MenuOption.scss';

type MenuOptionProps = {
    title: string;
    onClick: () => void;
    children?: ReactNode;
}

export const MenuOption = ({ title, onClick, children }: MenuOptionProps) => {
    return (
        <div className="menu-option" onClick={onClick}>
            {children ? <div className="menu-option-icon">{children}</div> : null}
            <div className="menu-option-title">{title}</div>
        </div>
    )
}