import React, { useEffect, useState } from 'react';

import '../popup.scss';
import Amount from '../../Amount/Amount';
import Button from '../../Button/Button';
import AccountSelect from '../../AccountSelect/AccountSelect';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { notify } from '../../../../utils/notify';
import { transferFunds } from '../../../../api/deposit';
import LoaderButton from '../../LoaderButton/LoaderButton';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmptyObject } from '../../../../helpers/isEmptyObject';
import { getAvailableWithdrawAmount } from '../../../../api/trading-account';

const TransferTemplate = (props) => {
  const {
    dismissModal,
    onCloseModal,
    onSuccess,
    onError,
    accountsData,
    defaultToAccount,
    defaultFromAccount,
    resetDefaultValue,
    ...other
  } = props;

  const getDefaultValue = () => {
    return {
      fromTransfer: isEmptyObject(defaultFromAccount)
        ? defaultFromAccount
        : { data: defaultFromAccount },
      toTransfer: isEmptyObject(defaultToAccount) ? defaultToAccount : { data: defaultToAccount },
    };
  };

  const {
    control,
    reset,
    resetField,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });

  const intl = useIntl();

  const title = 'Cashier';
  const [fromAccount, setFromAccount] = useState(defaultFromAccount);
  const [toAccount, setToAccount] = useState(defaultToAccount);
  const [accountsFrom, setAccountsFrom] = useState(accountsData);
  const [accountsTo, setAccountsTo] = useState(accountsData);
  const [amount, setAmount] = useState();
  const [availableWithdraw, setAvailableWithdraw] = useState(null);
  const [isLoading, setLoadStatus] = useState(false);
  const [error, setError] = useState(null);
  const validationText = intl.formatMessage({
    id: 'page.finance.transfer.popup.validation',
    defaultMessage: 'Not enough funds',
  });
  const closeModal = () => {
    dismissModal();
    onCloseModal();
  };

  useEffect(() => {
    if (fromAccount) {
      setAccountsTo(
        accountsData.filter(
          (item) =>
            item.trading_account !== fromAccount.trading_account &&
            item.amount_type === fromAccount.amount_type,
        ),
      );
    }

    if (toAccount) {
      setAccountsFrom(
        accountsData.filter(
          (item) =>
            item.trading_account !== toAccount.trading_account &&
            item.amount_type === toAccount.amount_type,
        ),
      );
    }
    if (fromAccount && toAccount) {
      setAccountsFrom(
        accountsData.filter((item) => item.trading_account !== toAccount.trading_account),
      );
      resetField('toTransfer');
    }

    if (
      (fromAccount?.amount_type === 'USD' && toAccount?.amount_type === 'EUR') ||
      (fromAccount?.amount_type === 'EUR' && toAccount?.amount_type === 'USD')
    ) {
      reset({
        ...getValues(),
        toTransfer: {
          data: null,
        },
      });
    }
  }, [fromAccount, toAccount]);

  const fetchAvailableWithdraw = async () => {
    if (fromAccount && fromAccount.source === 'WebTrader' && fromAccount.trading_account) {
      const response = await getAvailableWithdrawAmount(fromAccount.trading_account);
      setAvailableWithdraw(response.data);
    } else {
      setAvailableWithdraw(null);
    }
  };

  useEffect(() => {
    fetchAvailableWithdraw();
  }, [fromAccount]);

  const makeRequest = (e) => {
    setLoadStatus(true);

    transferFunds({
      amount: amount,
      from_trading_account: fromAccount.trading_account,
      to_trading_account: toAccount.trading_account,
    })
      .then((res) => {
        NotificationManager.success(
          intl.formatMessage({ id: 'transaction.transfer.alert.created' }),
          intl.formatMessage({ id: 'status.success.title' }),
          3000,
        );
        onSuccess();
        closeModal();
      })
      .catch((err) => {
        onError();
        notify({
          type: 'error',
          message: err.response,
          timeOut: 5000,
        });
      })
      .finally(() => {
        setLoadStatus(false);
        resetDefaultValue();
        reset();
      });
  };

  return (
    <div className={'wrapper popup-wrapper'}>
      <div className={'popup'}>
        <div className={'popup__title'}>
          <span md={12}>
            <FormattedMessage
              id="page.finance.transfer.popup.title"
              defaultMessage="Transfer funds"
            />
          </span>
          <i className={'popup__close-btn'} onClick={closeModal}></i>
        </div>
        <div className={'popup__content'}>
          <div className={'popup__content-left'}>
            <div className={'popup__content-info'}>
              <i className={'icon'}></i>
              {/* <span className={'info__title'}>
                <FormattedMessage
                  id="page.finance.transfer.popup.descr.title"
                  defaultMessage="Withdrawing from trading account"
                />
              </span> */}
              <span className={'info__description'}>
                <FormattedMessage
                  id="page.finance.transfer.popup.descr.text"
                  defaultMessage="Please read the Privacy Policy carefully. This document is an integral part of the agreement between you (also named “User”) and the Company. Privacy Policy describes the rules for (the Company) work with respect to your personal data. Personal data includes any User data that idenfie s him as an individual. This Policy also explains the use of personal data that we collect when you use our services by communica ng with us by phone, e-mail or by other means. This Policy informs you what type of informa on we collect, with whom we share it and for what purposes, how we protect it and what rights you have to preserve the confiden ality of your informaon. We are serious about protecng the personal data of our customers and preserving confiden al informa on. To avoid the loss of informa on, its misuse, altera on and unauthorized access to it by third par es, we comply with all requirements, both organiza onal and technical. By accep ng the Privacy Policy, the User agrees to the collec on, storage, use and disclosure of personal data in accordance with this Privacy Policy and applicable law. You can withdraw your consent and request that you remove your personal data by contacng us."
                />
              </span>
            </div>
          </div>
          <div className={'popup__content-right'}>
            <div className={'popup__content-controls'}>
              <span className={'controls__title'}>
                <FormattedMessage
                  id="page.finance.transfer.title"
                  defaultMessage="Transfer funds"
                />
              </span>
              <AccountSelect
                title={
                  <FormattedMessage
                    id="page.finance.transfer.field.fromTradingAccount.title"
                    defaultMessage="From trading account"
                  />
                }
                placeholder={<FormattedMessage id="placeholder.select" defaultMessage="Select" />}
                accountsData={accountsFrom}
                name="fromTransfer"
                listHeight={215}
                control={control}
                // defaultAccount={defaultFromAccount}
                handleChange={(data) => {
                  setFromAccount(data.data);
                }}
                hideDemo
                required
              />
              <AccountSelect
                title={
                  <FormattedMessage
                    id="page.finance.transfer.field.toTradingAccount.title"
                    defaultMessage="To trading account"
                  />
                }
                placeholder={<FormattedMessage id="placeholder.select" defaultMessage="Select" />}
                accountsData={accountsTo}
                name="toTransfer"
                listHeight={115}
                control={control}
                // defaultAccount={defaultToAccount}
                menuIsOpen
                handleChange={(data) => {
                  setToAccount(data.data);
                }}
                hideDemo
                required
              />
              <div className={'controls__amount'}>
                <Amount
                  currency={fromAccount?.amount_type}
                  buttons={[250, 500, 1000]}
                  error={error?.amountError}
                  disabled={!fromAccount}
                  onValueChange={(data) => {
                    setAmount(data);
                    if (data > Number(fromAccount.balance.replace(',', ''))) {
                      setError({
                        amountError: validationText,
                      });
                    } else {
                      setError(null);
                    }
                  }}
                  required
                  details={
                    availableWithdraw &&
                    `${intl.formatMessage({
                      id: 'withdraw.available',
                      defaultMessage: 'Available',
                    })}: ${availableWithdraw.available_balance_amount}`
                  }
                />
              </div>
              {/* <span className={'controls__warning'}>
                <FormattedMessage
                  id="page.finance.transfer.popup.note.text"
                  defaultMessage="Note: Withdrawal request will be riviewed by our operator within 24 hours."
                />
              </span> */}
              <div className={'controls__buttons'}>
                <Button
                  buttonText={
                    <FormattedMessage
                      id="page.finance.transfer.popup.btn.cancel"
                      defaultMessage="Cancel"
                    />
                  }
                  buttonType="secondary"
                  size="big"
                  onClick={closeModal}
                />
                <LoaderButton
                  buttonType="primary"
                  size="big"
                  showSpinner={isLoading}
                  disabled={!amount || !fromAccount || !toAccount || isLoading || error}
                  buttonText={
                    <FormattedMessage
                      id="page.finance.transfer.popup.btn.checkoutNow"
                      defaultMessage="Checkout now"
                    />
                  }
                  onClick={handleSubmit(makeRequest)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferTemplate;
