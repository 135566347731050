import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useAppSelector } from 'store';
import { selectRecaptchaKey } from 'store/slice/paymentsCurrency';
import { Context } from 'components/LangWrapper';

type Props = {
  setValue: UseFormSetValue<FieldValues>;
};

export const Recaptcha = React.forwardRef<any, Props>(({ setValue }, ref) => {
  const key = useAppSelector(selectRecaptchaKey);
  const lang = React.useContext(Context) as any;

  const [recaptchaKey, setRecaptchaKey] = React.useState(0);
  const [captchaLanguage, setCaptchaLanguage] = React.useState(lang.locale || 'en');

  React.useEffect(() => {
    // https://developers.google.com/recaptcha/docs/language
    setCaptchaLanguage(() => (lang.locale === 'cz' ? 'cs' : lang.locale));
    setRecaptchaKey((prevKey) => prevKey + 1);
    (ref as any).current.reset();
  }, [lang.locale]);

  const onChange = (value: string) => {
    setValue('captcha', value);
  };

  return (
    <ReCAPTCHA
      ref={ref}
      key={recaptchaKey}
      hl={captchaLanguage}
      sitekey={key}
      onChange={onChange}
    />
  );
});
