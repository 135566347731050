import { useEffect } from "react";

type Props = {
    active: boolean;
    listeners: {
        [key in keyof WindowEventMap]?: EventListenerOrEventListenerObject
    };
};

export default function useGlobalDOMEvents(props: Props) {
    useEffect(() => {
        if (props.active) {
            for (let [key, func] of Object.entries(props.listeners)) {
                window.addEventListener(key, func, false);
            }
        }
        return () => {
            if (props.active) {
                for (let [key, func] of Object.entries(props.listeners)) {
                    window.removeEventListener(key, func, false);
                }
            }
        };
    }, [props.active]);
}