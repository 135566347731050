import React, { useEffect } from 'react';
import { DashboardLayout } from '../UserView/DashboardLayout';
import Page from '../Page/Page';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import './MetaTrader.scss';
import ios from '../../assets/img/ios_new.png';
import android from '../../assets/img/android.png';
import metaTrader from '../../assets/img/metatrader5.png';
import computer from '../../assets/img/web_trader.png';
import { useSelector } from 'react-redux';
import { getPaymentsEnableWebTrader } from '../../utils/selectors';
import { selectWTUrl } from 'store/slice/paymentsCurrency';

const LINK = {
  IOS: process.env.REACT_APP_IOS,
  COMPUTER: './' + process.env.REACT_APP_COMPUTER,
  BROWSER: process.env.REACT_APP_BROWSER,
  ANDROID: './' + process.env.REACT_APP_ANDROID,
};

const MetaTrader = () => {
  const intl = useIntl();
  const ENABLE_WT = useSelector(getPaymentsEnableWebTrader);
  const WTUrl = useSelector(selectWTUrl);

  const changingTabTitle = () => {
    document.title = intl.formatMessage({
      id: 'menu.metaTrader',
      defaultMessage: 'Trading Platform',
    });
  };

  useEffect(() => {
    changingTabTitle();
  }, []);

  return (
    <DashboardLayout>
      <Page title={<FormattedMessage id="menu.metaTrader" defaultMessage="Trading Platform" />}>
        {ENABLE_WT !== null && (
          <div className={'meta-trader__page'}>
            {ENABLE_WT ? (
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col className={'mb-3'}>
                      <FormattedMessage
                        id="page.metaTrader_text_4"
                        defaultMessage="You can start using the trading platform directly from your browser:"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className={'meta-trader__page-webtrader'}>
                      <a
                        className={'meta-trader__link'}
                        href={WTUrl}
                        rel="nofollow noreferrer"
                        target="_blank"
                      >
                        WebTrader
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col xs={12} md={12} lg={6} xl={4}>
                    <Row className={'mb-3'}>
                      <Col>
                        <FormattedMessage
                          id="page.metaTrader_text_1"
                          defaultMessage="Start using the trading platform by downloading the application from the link:"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className={'mb-4'}>
                        <a
                          className={'meta-trader__link'}
                          href={LINK.COMPUTER}
                          rel="nofollow noreferrer"
                          download
                        >
                          <img
                            className={'download__img'}
                            src={metaTrader}
                            alt="Link to download Trading Platform"
                          />
                        </a>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={12} lg={6} xl={4}>
                    <Row>
                      <Col className={'mb-3'}>
                        <FormattedMessage
                          id="page.metaTrader_text_2"
                          defaultMessage="Or you can start using the trading platform directly from your browser:"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className={'mb-4'}>
                        <a
                          className={'meta-trader__link'}
                          href={LINK.BROWSER}
                          rel="nofollow noreferrer"
                          target="_blank"
                        >
                          <img
                            className={'download__img'}
                            src={computer}
                            alt="Link to Trading Platform"
                          />
                        </a>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Row>
                    <Col className={'mb-3'}>
                      <FormattedMessage
                        id="page.metaTrader_text_3"
                        defaultMessage="Download the app for Android or IOS:"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={6} xl={4} className={'mb-3'}>
                      <a
                        className={'ios__link'}
                        href={LINK.IOS}
                        rel="nofollow noreferrer"
                        target="_blank"
                      >
                        <img
                          className={'download__img'}
                          src={ios}
                          alt="Link to download IOS Trading Platform"
                        />
                      </a>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={4} className={'mb-3'}>
                      <a
                        className={'android__link'}
                        href={LINK.ANDROID}
                        rel="nofollow noreferrer"
                        download
                      >
                        <img
                          className={'download__img'}
                          src={android}
                          alt="Link to download Android Trading Platform"
                        />
                      </a>
                    </Col>
                  </Row>
                </Row>
              </>
            )}
          </div>
        )}
      </Page>
    </DashboardLayout>
  );
};

export default MetaTrader;
