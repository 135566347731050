import { useEffect, useRef } from "react";

export const useEvent = (eventName, eventHandler) => {
    const callbackRef = useRef(eventHandler)

    useEffect(() => {
        callbackRef.current = eventHandler
    });

    useEffect(() => {
        const callback = (e) => callbackRef.current(e);
        window.addEventListener(eventName, callback);
        return () => {
            window.removeEventListener(eventName, callback)
        }
    }, [eventName])
}