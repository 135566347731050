import React from 'react';
import './trading-table.scss';
import { makeTableData } from '../../../../helpers/table.helper';
import Table from '../Table';
import { TABLE_TRADING_COLUMNS_NAMES } from '../../../../constants/table';
import moment from 'moment';

const TradingTable = ({ data, perPage, showLoader, modifyDataHandler }) => {
  const columns = React.useMemo(() => TABLE_TRADING_COLUMNS_NAMES, []);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const {
      trade,
      type,
      open_time,
      open_time_dt,
      close_time,
      symbol,
      volume,
      open_price,
      close_price,
      swap,
      profit,
    } = item;
    return {
      trade: trade,
      type: <span className={type?.toLowerCase()}>{type}</span>,
      // close_time: (
      //   <div className={'date-cell'}>
      //     <span>{moment.unix(close_time).format('DD.MM.YYYY')}</span>
      //     <span>{moment.unix(close_time).format('HH:mm')}</span>
      //   </div>
      // ),
      symbol: symbol,
      open_price: open_price,
      volume: volume,
      open_time: (
        <div className={'date-cell'}>
          <span>{moment(open_time_dt, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')}</span>
          <span>{moment(open_time_dt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')}</span>
        </div>
      ),
      // close_price: close_price,
      // swap: swap,
      // profit: profit,
    };
  });

  return (
    <Table
      columns={columns}
      data={prepearedTableData}
      perPage={perPage}
      showLoader={showLoader}
      className={'trading-table'}
      getCellProps={(cellInfo) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : 'var(--body-background-color)'}`,
          },
        };
      }}
    />
  );
};

export default TradingTable;
