import React, { useEffect, useState } from 'react';
import '../popup.scss';
import Amount from '../../Amount/Amount';
import Button from '../../Button/Button';
import AccountSelect from '../../AccountSelect/AccountSelect';
import { DEPOSIT_TYPE, PAYMENTS } from '../../../../constants/deposit';
import LoaderButton from '../../LoaderButton/LoaderButton';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import WalletPopup from '../../Payments/WalletPopup/WalletPopup';
import LKSelect from '../../LKSelect';
import {
  getEnableBankCard,
  getEnableCreditCard,
  getPaymentsCurrency,
  getPaymentsCurrencyKasha,
  getPaymentsEnablePayment,
  getPaymentsGateways,
} from '../../../../utils/selectors';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../store';
import { addDeposit } from '../../../../api/deposit';
import { notify } from 'utils/notify';
import { IS_DWS_DOMAIN } from 'config';

const DepositTemplate = (props) => {
  const intl = useIntl();
  const {
    dismissModal,
    onCloseModal,
    onSuccess,
    onError,
    accountsData,
    defaultToAccount,
    ...other
  } = props;
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({});

  const [toAccount, setToAccount] = useState(defaultToAccount);
  const [amount, setAmount] = useState<number>(0);
  const [isLoading, setLoadingStatus] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const paymentsCurrency = useAppSelector(getPaymentsCurrency);
  const paymentsCurrencyKasha = useAppSelector(getPaymentsCurrencyKasha);
  const ENABLE_PAYMENT = useAppSelector(getPaymentsEnablePayment);
  const paymentsGateways = useAppSelector(getPaymentsGateways);

  const navigate = useNavigate();
  const paymentCurrency = watch('payment_currency');
  const gateWay = watch('gateway');

  const ENABLE_CREDIT_CARD = useAppSelector(getEnableCreditCard);
  const ENABLE_BANK_CARD = useAppSelector(getEnableBankCard);

  const closeModal = () => {
    dismissModal();
    onCloseModal?.();
  };

  const onSubmit = (data) => {
    if (ENABLE_CREDIT_CARD || ENABLE_BANK_CARD) {
      addDeposit({
        amount,
        amount_type: data?.payment_currency?.value,
        trading_account_id: toAccount?.trading_account,
        type: 'deposit',
        method: data?.payment_method?.label,
      })
        .then(() => {
          notify({
            type: 'success',
            message: intl.formatMessage({ id: 'transaction.deposit.alert.created.cca' }),
            timeOut: 5000,
          });
          closeModal();
        })
        .catch((err) => {
          notify({
            type: 'error',
            message: err.response,
            timeOut: 5000,
          });
        });
    } else {
      navigate('/payment/form', {
        state: {
          amount: amount,
          trading_account_id: toAccount?.trading_account,
          payment_currency: data?.payment_currency?.value,
          gateway: data?.gateway?.value,
        },
      });
    }
  };

  const paymentsMethodDeposit = [
    { value: 'Cryptocurrency', label: 'Crypto' },
    { value: 'Credit Card', label: 'credit_card_ca' },
    { value: 'Bank Card', label: 'bank_card_ca' },
  ];

  useEffect(() => {
    if (ENABLE_CREDIT_CARD && defaultToAccount) {
      setValue('payment_currency', { value: defaultToAccount.amount_type });
    }
  }, []);

  return (
    <div className={'wrapper popup-wrapper'}>
      <div className={'popup'}>
        <div className={'popup__title'}>
          <span>
            <FormattedMessage id="page.finance.deposit.popup.title" defaultMessage="Deposit" />
          </span>
          <i className={'popup__close-btn'} onClick={closeModal}></i>
        </div>
        <div className={'popup__content'}>
          <div className={'popup__content-left'}>
            <div className={'popup__content-info'}>
              <i className={'icon'}></i>
              {/* <span className={'info__title'}>
                <FormattedMessage
                  id="page.finance.deposit.popup.descr.title"
                  defaultMessage="Withdrawing from trading account"
                />
              </span> */}
              <span className={'info__description'}>
                {IS_DWS_DOMAIN ? (
                  <FormattedMessage
                    id="page.finance.deposit.popup.descr.dws.text"
                    defaultMessage="Your account manager will contact you to aid you in the activation process"
                  />
                ) : (
                  <FormattedMessage
                    id="page.finance.deposit.popup.descr.text"
                    defaultMessage="Request withdrawal from one of you trading accounts by selecting an account from the list, choosing a payment account and defining the desired withdrawal amount."
                  />
                )}
              </span>
            </div>
          </div>
          <div className={'popup__content-right'}>
            <div className={'popup__content-controls'}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <span className={'controls__title'}>
                  <FormattedMessage id="page.finance.deposit.title" defaultMessage="Deposit" />
                </span>
                {ENABLE_PAYMENT ? (
                  <>
                    <LKSelect
                      options={paymentsMethodDeposit}
                      name="payment_method"
                      title={
                        <FormattedMessage
                          id="page.finance.widthdraw.popup.paymentMethod"
                          defaultMessage="Payment Method"
                        />
                      }
                      control={control}
                      controlPadding="12px"
                      required
                      handleChange={(data) => {
                        setPaymentMethod(data.label);
                      }}
                    />
                    <br />
                  </>
                ) : null}

                {paymentMethod === 'credit_card' && ENABLE_PAYMENT && !ENABLE_CREDIT_CARD ? (
                  <>
                    <>
                      <LKSelect
                        options={paymentsGateways}
                        name="gateway"
                        title={intl.formatMessage({
                          id: 'page.finance.deposit.popup.gateway',
                          defaultMessage: 'Gateway',
                        })}
                        control={control}
                        controlPadding="12px"
                      />
                      <br />
                    </>
                    <LKSelect
                      options={
                        gateWay?.value === PAYMENTS.kasha ? paymentsCurrencyKasha : paymentsCurrency
                      }
                      name="payment_currency"
                      title={intl.formatMessage({
                        id: 'page.finance.deposit.popup.payment_currency',
                        defaultMessage: 'Payment currency',
                      })}
                      control={control}
                      listHeight={150}
                      controlPadding="12px"
                    />
                    <br />
                  </>
                ) : null}
                <AccountSelect
                  title={
                    <FormattedMessage
                      id="page.finance.deposit.popup.field.chooseTradingAccount.title"
                      defaultMessage="Choose trading account"
                    />
                  }
                  placeholder={<FormattedMessage id="placeholder.select" defaultMessage="Select" />}
                  control={control}
                  name="toDeposit"
                  listHeight={115}
                  accountsData={accountsData}
                  defaultAccount={defaultToAccount}
                  handleChange={(data) => {
                    setToAccount(data.data);
                    if (ENABLE_CREDIT_CARD && paymentCurrency?.value !== data.data.amount_type) {
                      setValue('payment_currency', { value: data.data.amount_type });
                      setAmount(0);
                    }
                  }}
                  hideDemo
                />
              </form>
              <div className={'controls__amount'}>
                <Amount
                  currency={paymentCurrency?.value || toAccount?.amount_type}
                  buttons={[250, 500, 1000]}
                  onValueChange={(data) => setAmount(data)}
                  required
                  val={amount}
                />
              </div>
              <div className={'controls__buttons'}>
                <Button
                  buttonText={
                    <FormattedMessage
                      id="page.finance.deposit.popup.btn.cancel"
                      defaultMessage="Cancel"
                    />
                  }
                  buttonType="secondary"
                  size="big"
                  onClick={closeModal}
                />

                {(paymentMethod === 'credit_card_ca' || paymentMethod === 'bank_card_ca') &&
                ENABLE_PAYMENT ? (
                  <LoaderButton
                    buttonType="primary"
                    size="big"
                    showSpinner={isLoading}
                    disabled={!amount || !toAccount || isLoading}
                    buttonText={intl.formatMessage({
                      id: 'page.finance.withdraw.popup.btn.checkoutNow',
                    })}
                    onClick={handleSubmit(onSubmit)}
                  />
                ) : (
                  <WalletPopup
                    trigger={
                      <LoaderButton
                        buttonType="primary"
                        size="big"
                        showSpinner={isLoading}
                        disabled={
                          !amount || !toAccount || isLoading || (ENABLE_PAYMENT && !paymentMethod)
                        }
                        buttonText={
                          <FormattedMessage
                            id="page.finance.deposit.popup.btn.checkout"
                            defaultMessage="Сheckout"
                          />
                        }
                      />
                    }
                    paymentData={{
                      amount: amount,
                      amount_type: toAccount?.amount_type,
                      trading_account_id: toAccount?.trading_account,
                      type: DEPOSIT_TYPE.DEPOSIT,
                    }}
                    onCloseModal={() => console.log('Finance scope - onCloseModal')}
                    onSuccess={() => {
                      onSuccess?.();
                    }}
                    onError={() => console.log('Finance scope - onError')}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositTemplate;
