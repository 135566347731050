import React from "react";
import { openInNewTab } from "../../helpers/openInNewTab";
import { chatUrl } from "../../../../api/chat";
import { getMessageFileConfig } from "../../helpers/getMessageFileConfig";
import { prettifyFileName } from "../../helpers/prettifyFileName";
import { getFileExtension } from "../../helpers/getFileExtension";

type DocumentBodyProps = {
    document: string;
    filename: string;
};

export const DocumentBody = ({ filename, document }: DocumentBodyProps) => {
    const filetype = getFileExtension(filename);

    return (
        <div className="chat-message-document" onClick={() => openInNewTab(chatUrl + document)}>
            {getMessageFileConfig(filetype).icon} {prettifyFileName(filename) + '.' + filetype}
        </div>
    )
}