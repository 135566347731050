import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../store/slice/authSlice';

import './signin.scss';
import FormInput from '../../../components/ui/FormInput';
import LangSwitcher from '../../../components/ui/LangSwitcher';
import Button from '../../../components/ui/Button';
import { Fingerprint } from 'phosphor-react';
import { Col, Container, Row } from 'react-bootstrap';
import Preloader from '../../../components/ui/Preloader/Preloader';
import PasswordInput from '../../../components/ui/PasswordInput/PasswordInput';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { PASSWORD_INPUT_VALIDATION } from '../../../constants/validators';
import { useWindowWidth } from '@react-hook/window-size';
import axios from 'axios';
import { getCompanyName, getLoading, selectAuth, getImageSignIn, getProperLogo } from '../../../utils/selectors';
import { AppDispatch } from '../../../store';
import { useRegionValidation } from '../../../utils/useRegionValidation';

const SignIn = () => {
  const auth = useSelector(selectAuth);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const width: number = useWindowWidth();
  const intl: IntlShape = useIntl();
  const [IP, setIP] = useState<string>('');
  const isLoading = useSelector(getLoading);
  const logo = useSelector(getProperLogo);
  const signInImage = useSelector(getImageSignIn);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const companyName = useSelector(getCompanyName);
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/');
    setIP(res.data.IPv4);
  };

  const onSubmit = (data): void => {
    data = {
      ...data,
      ip: IP,
    };

    dispatch(login(data)).then((res): void => {
      if (res && res.meta.requestStatus === 'fulfilled') {
        navigate('/finance');
      }
    });
  };
  // const changingTabTitle = (): void  => {
  //   document.title = intl.formatMessage({
  //     id: 'auth.signIn.title',
  //     defaultMessage: 'Sign in',
  //   });
  // };

  useRegionValidation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    console.log('token', token)
    if (token) {
      try {
        localStorage.setItem('token', token);
          setTimeout(() => {
            window.location.replace('/finance');
          }, 100);
      } catch (error) {
        console.error('ERROR: ', error);
      }
    }
}, [window.location.search]);

  useEffect((): void => {
    if (auth.isAuthorized) {
      navigate('/finance');
    }

    getData();
    // changingTabTitle();
  }, []);

  return (
    <>
      <div className={'sign_in'}>
        <div className={'page'}>
          <div className={'login auth'}>
            <div className={'logo'}>
              <img src={logo} alt='logo' />
            </div>
            <div className={'login__col auth__descr'}>
              <div className={'auth__descr-img'}>
                {
                  signInImage ?
                    <img src={signInImage} alt='' />
                    :
                    <Fingerprint size={170} />
                }
              </div>
              <div className={'auth__descr-text'}>
                <p>
                  <FormattedMessage
                    id='auth.signIn.singUp.doNotHaveAccount'
                    defaultMessage='Do not have an account yet?'
                  />
                </p>
                <Link to='/sign-up' className={'link-text'}>
                  <FormattedMessage id='auth.signIn.singUp.btn' defaultMessage='Sign up here' />
                </Link>
              </div>
            </div>
            <div className={'login__col'}>
              <div className={'login__header'}>
                <div className={'login__title'}>
                  {/*<FormattedMessage id="auth.signIn.title" defaultMessage="Sign in" />*/}
                </div>
                <LangSwitcher />
              </div>
              <div className={'login__content'}>
                <form className={'form'} onSubmit={handleSubmit(onSubmit)}>
                  {isLoading ? <Preloader /> : null}
                  <Container className={isLoading ? 'p-0 blur' : 'p-0'}>
                    <Row className={'form__line'}>
                      <Col md={12}>
                        <FormInput
                          name='email'
                          type='email'
                          autocomplete={'email'}
                          label={
                            <FormattedMessage
                              id='auth.signIn.fieldName.email'
                              defaultMessage='E-mail'
                            />
                          }
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: (
                                <FormattedMessage
                                  id='form.error.requiredField'
                                  defaultMessage='Field is required'
                                  values={{
                                    fieldName: 'E-mail',
                                    //@ts-ignore
                                    code: (fieldName) => <span>{fieldName}</span>,
                                  }}
                                />
                              )
                            },
                            validate: {
                              correctEmail: (value) =>
                                /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || (
                                  <FormattedMessage
                                    id='form.error.email'
                                    defaultMessage='Incorrect E-mail address'
                                  />
                                ),
                            },
                          }}
                          errors={errors.email}
                        />
                      </Col>
                      <Col md={12}>
                        <PasswordInput
                          name='password'
                          type='password'
                          autocomplete={'current-password'}
                          label={
                            <FormattedMessage
                              id='auth.signIn.fieldName.password'
                              defaultMessage='Password'
                            />
                          }
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: (
                                <FormattedMessage
                                  id='form.error.passwordMessage_7'
                                  defaultMessage='Password field is required'
                                />
                              )
                            },
                            validate: PASSWORD_INPUT_VALIDATION,
                          }}
                          errors={errors.password}
                        />
                      </Col>
                    </Row>
                    {width <= 500 ? (
                      <Row>
                        <Col md={12}>
                          <div className={'auth__descr-text'}>
                            <p>
                              <FormattedMessage
                                id='auth.signIn.singUp.doNotHaveAccount'
                                defaultMessage='Do not have an account yet?'
                              />
                            </p>
                            <Link to='/sign-up' className={'link-text'}>
                              <FormattedMessage
                                id='auth.signIn.singUp.btn'
                                defaultMessage='Sign up here'
                              />
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ''
                    )}
                    <div className={'button-wrap'}>
                      <Link to='/forgot-password' className={'text-link'}>
                        <FormattedMessage
                          id='auth.signIn.forgotPassword'
                          defaultMessage='Forgot your password?'
                        />
                      </Link>
                      <Button
                        type='submit'
                        buttonText={
                          <FormattedMessage id='auth.signIn.button' defaultMessage='Sign in' />
                        }
                        size='big'
                      />
                    </div>
                  </Container>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'copyright'}>
        © {new Date().getFullYear()} {companyName}, Inc. All rights Reserved
      </div>
    </>
  );
};

export default SignIn;
