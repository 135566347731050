import React from "react";
import cn from "classnames";
import { ChatMessageType, OnlineStatusesVerbose, UserTypeLabels } from "../../../../types/Chat";
import { ChatAvatar } from "../ChatAvatar/ChatAvatar";
import { ChatDate } from "../ChatDate/ChatDate";
import { ChatMessageBody } from "./ChatMessageBody";
import './ChatMessage.scss';

type ChatMessageProps = {
    message: ChatMessageType;
    userStatus?: OnlineStatusesVerbose;
    isReply?: boolean;
    userId: string;
    onContextMenu?: (event: any, entity: any) => void;
    activeViaContextMenu?: boolean;
};

export const ChatMessage = ({ message, userStatus, isReply, userId, onContextMenu, activeViaContextMenu }: ChatMessageProps) => {
    const { owner, owner_id, body, type_verbose, created_at, image, document, reply_to, edited } = message;
    const ownerName = userId === owner_id ? owner.name : 'Support';

    return (
        <div
            className={cn('chat-message', { isReply, active: activeViaContextMenu })}
             onContextMenu={(e) => !isReply && onContextMenu(e, message)}
        >
            {!isReply ? (
                <ChatAvatar
                    name={ownerName}
                    status={userStatus === OnlineStatusesVerbose.ONLINE}
                    className={UserTypeLabels[owner.base.type]}
                />) : null}
            <div className='chat-message-content'>
                <div className="chat-message-meta">
                    <div className="chat-message-name">{ownerName}</div>
                    <ChatDate date={created_at} dateFormat='distance' />
                </div>
                {reply_to ? <ChatMessage message={reply_to} isReply={true} userId={userId}/> : null}
                <ChatMessageBody body={body} type={type_verbose} image={image} document={document} edited={edited}/>
            </div>
        </div>
    )
}