const UploadFileIcon = (props) => {
  return (
    <svg width="45" height="44" viewBox="0 0 45 44" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.2276 18.1265C14.5549 17.9592 13.8642 17.8747 13.1709 17.875H13.1704C10.9824 17.875 8.88395 18.7442 7.33678 20.2914C5.7896 21.8385 4.92041 23.937 4.92041 26.125C4.92041 28.313 5.7896 30.4115 7.33678 31.9586C8.88395 33.5058 10.9824 34.375 13.1704 34.375H17.2954C18.0548 34.375 18.6704 34.9906 18.6704 35.75C18.6704 36.5094 18.0548 37.125 17.2954 37.125H13.1704C10.253 37.125 7.45514 35.9661 5.39224 33.9032C3.32934 31.8403 2.17041 29.0424 2.17041 26.125C2.17041 23.2076 3.32934 20.4097 5.39224 18.3468C7.45501 16.284 10.2527 15.1251 13.1699 15.125M13.1704 15.125C14.0876 15.1247 15.0013 15.2365 15.8913 15.4578C16.6283 15.6411 17.0771 16.3871 16.8938 17.1241C16.7105 17.861 15.9645 18.3098 15.2276 18.1265" />
        <path fillRule="evenodd" clipRule="evenodd" d="M32.2087 10.26C30.3485 9.63997 28.3676 9.47082 26.4292 9.76654C24.4907 10.0622 22.6503 10.8144 21.0596 11.9609C19.4689 13.1074 18.1734 14.6155 17.2799 16.361C16.3864 18.1064 15.9204 20.0392 15.9204 22C15.9204 22.7594 15.3048 23.375 14.5454 23.375C13.786 23.375 13.1704 22.7594 13.1704 22C13.1704 19.6034 13.7399 17.2411 14.832 15.1078C15.9241 12.9745 17.5075 11.1313 19.4517 9.72996C21.3959 8.32866 23.6453 7.40941 26.0144 7.04799C28.3836 6.68656 30.8048 6.8933 33.0784 7.65117C35.352 8.40903 37.4129 9.69633 39.0914 11.407C40.7699 13.1176 42.0178 15.2026 42.7324 17.4902C43.447 19.7778 43.6078 22.2024 43.2014 24.5643C42.7951 26.9262 41.8334 29.1577 40.3954 31.075C39.9398 31.6825 39.0779 31.8056 38.4704 31.35C37.8629 30.8944 37.7398 30.0325 38.1954 29.425C39.3719 27.8563 40.1588 26.0305 40.4913 24.0981C40.8237 22.1656 40.6922 20.1818 40.1075 18.3102C39.5229 16.4385 38.5018 14.7326 37.1285 13.333C35.7552 11.9334 34.0689 10.8801 32.2087 10.26Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M25.9481 21.0277C26.4851 20.4908 27.3557 20.4908 27.8927 21.0277L33.7192 26.8543C34.2562 27.3913 34.2562 28.2619 33.7192 28.7988C33.1823 29.3358 32.3117 29.3358 31.7747 28.7988L26.9204 23.9445L22.0661 28.7988C21.5291 29.3358 20.6585 29.3358 20.1216 28.7988C19.5846 28.2619 19.5846 27.3913 20.1216 26.8543L25.9481 21.0277Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M26.9204 20.625C27.6798 20.625 28.2954 21.2406 28.2954 22V35.75C28.2954 36.5094 27.6798 37.125 26.9204 37.125C26.161 37.125 25.5454 36.5094 25.5454 35.75V22C25.5454 21.2406 26.161 20.625 26.9204 20.625Z" />
    </svg>
  );
};

export default UploadFileIcon;
