import { notify } from './notify';

export const copyToBuffer = (text, displayText, title): void => {
  if (text) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      notify({
        type: 'success',
        title: title || 'Copied to buffer',
        message: `${displayText ? text : ''}`,
        timeOut: 3000,
      });
    } else {
      const textField: HTMLTextAreaElement = document.createElement('textarea');
      textField.innerText = text;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      notify({
        type: 'success',
        title: title || 'Copied to buffer',
        message: `${displayText ? text : ''}`,
        timeOut: 3000,
      });
    }
  }
};
