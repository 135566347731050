import React, { useState, useEffect, ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import './forgotPassword.scss';
import { Link } from 'react-router-dom';
import FormInput from '../../../components/ui/FormInput';
import LangSwitcher from '../../../components/ui/LangSwitcher';
import Button from '../../../components/ui/Button';
import { Key, CheckCircle } from 'phosphor-react';
import { Container, Row, Col } from 'react-bootstrap';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { notify } from '../../../utils/notify';

import { resetPassword } from '../../../api/auth';
import { FormattedMessage, IntlShape } from 'react-intl';
import { useWindowWidth } from '@react-hook/window-size';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCompanyName, getImageResetPassword, getProperLogo } from '../../../utils/selectors';
import * as LOGOS from '../../../assets/img/'

const ForgotPassword = () => {
  const title: string = 'Reset password';
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const companyName = useSelector(getCompanyName);
  const logo = useSelector(getProperLogo);
  const imageResetPassword = useSelector(getImageResetPassword);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState<boolean>(false);
  const width: number = useWindowWidth();

  const intl: IntlShape = useIntl();

  const changingTabTitle = (): void => {
    const title: string = intl.formatMessage({
      id: 'auth.resetPassword.title',
      defaultMessage: 'Reset password',
    });
    document.title = title;
  };

  useEffect((): void => {
    changingTabTitle();
  }, [title]);

  const onSubmit = (data): void => {
    setIsLoading(true);
    resetPassword(data)
      .then((response): void => {
        if (!response.errors) {
          setIsLoading(false);
          setRequestStatus(true);
          notify({
            type: 'success',
            message: response.data.message,
            timeOut: 3000,
          });
        }
      })
      .catch((error): void => {
        if (error && error.response.data !== null) {
          setIsLoading(false);
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  const renderSuccessfullyRequest = () => (
    <div className={'forgot__password'}>
      <div className={'page'}>
        <div className={'page__box'}>
          <div className={'page__box-header'}>
            <div className={'logo'}>
              <img src={logo} alt='logo' />
            </div>
            <div className={'page__box-header-icon'}>
              <CheckCircle size={132} />
            </div>
            <div className={'page__box-lang-switcher'}>
              <LangSwitcher />
            </div>
          </div>
          <div className={'page__box-content text-page'}>
            <p className={'title'}>
              <FormattedMessage
                id='letter.title'
                defaultMessage='The letter was successfully sent.'
              />
            </p>
            <p>
              <FormattedMessage id='letter.dscr' defaultMessage='Please check.' />
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {!requestStatus ? (
        <div className={'forgot__password'}>
          <div className={'page'}>
            <div className={'login auth'}>
              <div className={'login__col auth__descr'}>
                <div className={'auth__descr-img'}>
                  {
                    imageResetPassword ?
                      <img src={imageResetPassword} alt='' />
                      :
                      <Key size={170} />
                  }
                </div>
                <div className={'auth__descr-text'}>
                  <p>
                    <FormattedMessage
                      id='auth.resetPassword.sideBar.title'
                      defaultMessage='Do not have an account yet?'
                    />
                  </p>
                  <Link to='/sign-up' className={'link-text'}>
                    <FormattedMessage
                      id='auth.resetPassword.sideBar.btnText'
                      defaultMessage='Sign up here'
                    />
                  </Link>
                </div>
              </div>
              <div className={'login__col'}>
                <div className={'login__header'}>
                  <div className={'login__title'}>
                    <FormattedMessage id='auth.resetPassword.title' defaultMessage='Reset password' />
                  </div>
                  <LangSwitcher />
                </div>
                <div className={'login__content'}>
                  <form className={'form'} onSubmit={handleSubmit(onSubmit)}>
                    {isLoading ? <Preloader /> : null}
                    <Container className={isLoading ? 'p-0 blur' : 'p-0'}>
                      <Row className={'form__line'}>
                        <Col md={12}>
                          <FormInput
                            name='email'
                            type='email'
                            label='E-mail'
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: (
                                  <FormattedMessage
                                    id='form.error.requiredField'
                                    defaultMessage='Field is required'
                                    values={{
                                      fieldName: 'E-mail',
                                      //@ts-ignore
                                      code: (fieldName) => <span>{fieldName}</span>,
                                    }}
                                  />
                                )
                              },
                              validate: {
                                correctEmail: (value) =>
                                  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || (
                                    <FormattedMessage
                                      id='form.error.email'
                                      defaultMessage='Incorrect E-mail address'
                                    />
                                  ),
                              },
                            }}
                            errors={errors.email}
                          />
                        </Col>
                      </Row>
                      {width <= 580 ? (
                        <Row>
                          <Col>
                            <div className={'auth__descr-text'}>
                              <p>
                                <FormattedMessage
                                  id='auth.resetPassword.sideBar.title'
                                  defaultMessage='Do not have an account yet?'
                                />
                              </p>
                              <Link to='/sign-up' className={'link-text'}>
                                <FormattedMessage
                                  id='auth.resetPassword.sideBar.btnText'
                                  defaultMessage='Sign up here'
                                />
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        ''
                      )}
                      <div className={'button-wrap'}>
                        <Button
                          type='submit'
                          buttonText={
                            <FormattedMessage
                              id='auth.resetPassword.btnText'
                              defaultMessage='Reset password'
                            />
                          }
                          size='big'
                          disabled={isLoading}
                        />
                      </div>
                    </Container>
                  </form>
                </div>
              </div>
              <div className={'logo'}>
                <img src={logo} alt='logo' />
              </div>
            </div>
          </div>
        </div>
      ) : (
        renderSuccessfullyRequest()
      )}
      <div className={'copyright'}>
        © {new Date().getFullYear()} {companyName}, Inc. All rights Reserved
      </div>
    </>
  );
};

export default ForgotPassword;
