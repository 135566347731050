import { createSlice } from "@reduxjs/toolkit";

import * as LOGOS from '../../assets/img/'

interface ISettingsCompany {
  companyName: string,
  images: {
    logo: string | null,
    properLogo: any,
    signUp: string | null,
    signIn: string | null,
    forgotPassword: string | null
  }
}

const getProperLogo = (logoURL: string) => {
  if (logoURL) {
    return `${logoURL}?time=${new Date().getTime()}`;
  }

  const staticLogo = LOGOS[process.env.REACT_APP_DEFAULT_ICON];

  if (typeof staticLogo === 'string') {
    return staticLogo;
  }

  return staticLogo.default;
}

const initialState: ISettingsCompany = {
  companyName: `${process.env.REACT_APP_COMPANY_NAME}`,
  images: {
    logo: JSON.parse(localStorage.getItem('theme_logo')) || null,
    properLogo: getProperLogo(''),
    signUp: JSON.parse(localStorage.getItem('theme_signUp')) || null,
    signIn: JSON.parse(localStorage.getItem('theme_signIn')) || null,
    forgotPassword: JSON.parse(localStorage.getItem('theme_forgotPassword')) || null,
  }
}

const settingsCompanySlice = createSlice({
  name: 'companyName',
  initialState,
  reducers: {
    setNameCompany: (state, action): void => {
      state.companyName = action.payload
    },
    setLogoCompany: (state, action): void => {
      state.images.logo = action.payload
      state.images.properLogo = getProperLogo(action.payload)
    },
    setSignUpImage: (state, action): void => {
      state.images.signUp = action.payload
    },
    setSignInImage: (state, action): void => {
      state.images.signIn = action.payload
    },
    setForgotPasswordImage: (state, action): void => {
      state.images.forgotPassword = action.payload
    },
  }
})

export const {
  setNameCompany,
  setLogoCompany,
  setSignUpImage,
  setSignInImage,
  setForgotPasswordImage,
} = settingsCompanySlice.actions;
export default settingsCompanySlice.reducer;
