import React, { useEffect, useRef, useState } from 'react';
import './filter.scss';
import { Col, Row } from 'react-bootstrap';
import { ArrowsClockwise } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import AccountSelect from '../../../../components/ui/AccountSelect/AccountSelect';
import Button from '../../../../components/ui/Button/Button';
import DatePickerRange from '../../../../components/ui/DatePickerRange/DatePickerRange';
import { FormattedMessage, useIntl } from 'react-intl';
import { getSymbols } from '../../../../api/opened-positions';
import LKSelect from '../../../../components/ui/LKSelect';
import { getTradingAccounts } from '../../../../api/trading-account';
import { getTimesTamp } from '../../../../utils/getTimesTamp';

const OpenedPositionsFilters = ({ onSubmit, reloadFilters, symbolList }) => {
  const intl = useIntl();
  const componentMounted = useRef(true);

  const getDefaultValue = () => {
    return {
      open: '',
      trading_account: '',
      symbol: '',
    };
  };
  const { handleSubmit, reset, getValues, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields } = formState;
  // const [symbolList, setSymbolList] = useState([]);
  const [tradingAccounts, setTradingAccounts] = useState([]);

  const submit = (data) => {

    const postData = {
      trading_account: data['trading_account'] && data['trading_account'].value !== 'any' ? data['trading_account'].value : null,
      open: getTimesTamp(data['open']),
      symbol: data?.symbol?.value || null,
    };

    onSubmit(postData);
  };

  const resetForm = () => {
    reset(getDefaultValue());
    onSubmit(null);
  };

  useEffect(() => {
    getTradingAccounts()
      .then((res) => {
        if (componentMounted.current) {
          setTradingAccounts(res.data.data);
        }
      })
      .catch((err) => console.log(err));

    // getSymbolsList();
    return () => {
      componentMounted.current = false;
    };
  }, []);

  // const getSymbolsList = () => {
  //   getSymbols()
  //     .then(res => {
  //       const opt = res?.data?.ticker.map((opt) => ({
  //         value: opt,
  //         label: opt,
  //       }));
  //       setSymbolList(opt);
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // };

  const isFormChange = () => {
    return Object.keys(dirtyFields).length;
  };

  return (
    <div className={'opened-position__filter'}>
      <form onSubmit={handleSubmit(submit)} className={'filters'}>
        <Row>
          <Col md={4}>
            <AccountSelect
              name='trading_account'
              control={control}
              title={
                <FormattedMessage
                  id='menu.accounts'
                  defaultMessage='Accounts'
                />
              }
              accountsData={tradingAccounts}
              controlPadding='3px 5px'
            />
          </Col>
          <Col md={4}>
            <LKSelect
              options={symbolList}
              name='symbol'
              title={
                <FormattedMessage
                  id='table.th.symbol'
                  defaultMessage='Symbol'
                />
              }
              control={control}
              controlPadding='3px 5px'
            />
          </Col>
          <Col md={4}>
            <div className={'datapicker'}>
              <DatePickerRange
                name='open'
                control={control}
                label={
                  <FormattedMessage
                    id='page.trading.history.range.open.title'
                    defaultMessage='Open time range'
                  />
                }
                placeholder={
                  intl.formatMessage({
                    id: 'page.trading.history.range.placeholder',
                    defaultMessage: 'From - To',
                  })
                }
              />
            </div>
          </Col>
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={'filters__btns'}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color='var(--white)' size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText={
                <FormattedMessage
                  id='personalInfo.form.field.btn.reset'
                  defaultMessage='Reset'
                />
              }
              type='reset'
              onClick={() => resetForm()}
              disabled={!isFormChange()}
            />
            <Button
              buttonText={
                <FormattedMessage
                  id='page.trading.history.form.apply'
                  defaultMessage='Apply'
                />
              }
              type='submit'
              onClick={handleSubmit(submit)}
              disabled={!isFormChange()}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OpenedPositionsFilters;
