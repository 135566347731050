import React, { useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { CalendarBlank } from 'phosphor-react';
import { useOnClickOutside } from '../../../utils/useClickOutside';
import './calendarPicker.scss';
import './customStyle.scss';
import moment from 'moment';
import Tooltip from '../Tooltip/Tooltip';

const CalendarPicker = (props) => {
  const { field } = useController(props);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const contentRef = useRef(null);

  const handleSelect = (date) => {
    const dateSelect = moment(date).valueOf();
    field.onChange(dateSelect);
  };

  useOnClickOutside(contentRef, () => {
    setShowDatePicker(false);
  });

  return (
    <div className='calendar-picker'>
      <div className={'date-range'}>
        <div className={'label-wrap'}>
          {props.label && (
            <label htmlFor={props.id} className={props.errors ? 'label error' : 'label'}>
              {props.label}
              {props.rules && props.rules.required ? <span className={'required'}>*</span> : ''}
            </label>
          )}
          {props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}
        </div>
        <div className={'date-input'}>
          <input
            className={'date-input__input'}
            type="text"
            value={field.value ? moment(field.value).format('DD.MM.YYYY') : null}
            onFocus={() => setShowDatePicker(true)}
            disabled={props.disabled}
          />
          <span className={'icon'}>
          <CalendarBlank size={24} />
        </span>
        </div>
        {showDatePicker && (
          <div className={'date-range'} ref={contentRef}>
            <Calendar onChange={(e) => handleSelect(e)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarPicker;
