import React from 'react';
import Popup from 'reactjs-popup';
import { Col, Row } from 'react-bootstrap';
import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import { Warning } from 'phosphor-react';

const ShowPassword = ({
                        triggerBtn,
                        errorsDetails,
                      }) => {

  const closeModal = (closeModal) => {
    closeModal();
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            dismissModal={() => closeModal(close)}
            headerTitle={'Errors'}
            rightContent={
              <div className={'content'}>
                <div className={'content-form popupForm'}>
                  <form>
                    <Row>
                      <Col md={12}>
                        <ul>
                          {errorsDetails && errorsDetails?.map((detail, index) => (
                            <li key={index}>
                              <Warning size={24} color='#ec0909' /> {detail}
                            </li>
                          ))}
                        </ul>
                      </Col>
                    </Row>
                    <div className={'content-controls'}>
                      <div className={'controls__buttons'}>
                        <Button
                          buttonText='OK'
                          buttonType='outline'
                          size='big'
                          type='button'
                          onClick={() => closeModal(close)}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default ShowPassword;
