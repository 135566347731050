import React from 'react';
import  './private-error-page.scss';
import { DashboardLayout } from '../UserView/DashboardLayout';
import Page from '../Page/Page';
import ErrorPage from '../ErrorPage/ErrorPage';

const PrivateErrorPage = () => {
  return (
    <DashboardLayout>
      <Page>
        <ErrorPage isPrivateRoute={true} />
      </Page>
    </DashboardLayout>
  );
};

export default PrivateErrorPage;
