import React, { useEffect, useRef, useState } from 'react';

import './accounts.scss';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import Page from '../../Page/Page';
import { getTradingAccounts } from '../../../api/trading-account';
import ControlsGroup from '../../../components/ui/ControlsGroup/ControlsGroup';
import AccountsTable from '../../../components/ui/Table/AccountsTable/AccountsTable';
import Button from '../../../components/ui/Button/Button';
import NewAccountPopup from '../../../components/ui/NewAccountPopup/NewAccountPopup';
import { setHeaderInfo } from '../../../store/slice/headerInfoSlice';
import { getHeaderInfo } from '../../../api/myProfile';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import TablePagination from '../../../components/ui/TablePagination/TablePagination';

const Accounts = () => {
  const componentMounted = useRef(true);
  const [tradingAccounts, setTradingAccounts] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(true);
  const [tableMeta, setTableMeta] = useState(null);
  const [tableLinks, setTableLinks] = useState(null);
  const [perPageCount, setPerPageCount] = useState(null);

  const dispatch = useDispatch();
  const intl = useIntl();

  const changingTabTitle = () => {
    document.title = intl.formatMessage({
      id: 'trading.accounts.title',
      defaultMessage: 'Trading Accounts',
    });
  };

  useEffect(() => {
    getTableData(null);
    changingTabTitle();
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getTableData = (options) => {
    setTableDataLoading(true);

    getTradingAccounts(getTablePageParams(options))
      .then((res) => {
        if (componentMounted.current) {
          const accList = res.data.data;
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setTradingAccounts(accList);
        }
        setTableDataLoading(false);
      })
      .catch((err) => {
        setTableDataLoading(false);
      });
  };

  const updateHeaderInfo = () => {
    getHeaderInfo()
      .then((res) => {
        const info = res.data.data;
        dispatch(setHeaderInfo(info));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTablePageParams = (options) => {
    if (!options) {
      return '';
    }

    const { page, navigate, perPage } = options;
    let params;
    let perPageParams = `per_page=${perPage || perPageCount}`;
    params = perPageParams;

    if (page) {
      params = `${params}&page=${page}`;
    } else if (navigate) {
      let toPage;
      toPage = tableLinks[navigate].split('?').reverse()[0];
      params = `${toPage}&${params}`;
    }

    return params;
  };

  return (
    <DashboardLayout>
      <Page
        title={<FormattedMessage id="trading.accounts.title" defaultMessage="Trading Accounts" />}
      >
        <div className={'new-account__page'}>
          <NewAccountPopup
            trigger={
              <Button
                buttonText={
                  <FormattedMessage id="trading.accounts.btn" defaultMessage="+ New Account" />
                }
                size="big"
              />
            }
            onClose={(data) => {
              if (data) {
                getTableData(null);
              }
            }}
          />
        </div>
        <ControlsGroup>
          <AccountsTable
            data={tradingAccounts}
            perPage={tradingAccounts?.length}
            showLoader={tableDataLoading}
            modifyDataHandler={() => {
              getTableData(null);
              updateHeaderInfo();
            }}
          />
          {tradingAccounts?.length ? (
            <TablePagination
              goToFirstPage={() => {
                getTableData({ navigate: 'first' });
              }}
              goToPrevPage={() => {
                getTableData({ navigate: 'prev' });
              }}
              goToNextPage={() => {
                getTableData({ navigate: 'next' });
              }}
              goToLastPage={() => {
                getTableData({ navigate: 'last' });
              }}
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                getTableData({ perPage: value });
              }}
              goToSelectedPage={(page) => {
                getTableData({ page });
              }}
              isFirstPageDisable={() => tableMeta?.current_page === 1}
              isPrevPageDisable={() => !tableLinks?.prev}
              isNextPageDisable={() => !tableLinks?.next}
              isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
            />
          ) : (
            ''
          )}
        </ControlsGroup>
      </Page>
    </DashboardLayout>
  );
};

export default Accounts;
