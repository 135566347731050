import moment from "moment";
import { endOfDay, parseISO, startOfDay } from "date-fns";

export const getTimesTamp = (data): undefined | object => {
  if (!data) {
    return;
  }

  if (!data.from && !data.to) {
    return;
  }

  const res: object = {};

  if (data.from) {
    res['from'] = moment(startOfDay(parseISO(data['from']))).format("X");
  }

  if (data.to) {
    res['to'] = moment(endOfDay(parseISO(data['to']))).format("X");
  }

  return res;
}
