import React from 'react';
import './button.scss';

const Button = (props, ref) => {
  const { buttonText, size, buttonType, icon, classes, buttonStyle, onClick, disabled, ...other } =
    props;

  return (
    <button
      className={`btn ${buttonType ? buttonType : ''} ${size ? size : ''} ${buttonStyle ? buttonStyle : ''}`}
      onClick={onClick}
      disabled={disabled}
      {...other}
    >
      {buttonText && <span className={'btn__text'}>{buttonText}</span>}
      {icon ? <span className={'btn__icon'}>{icon}</span> : ''}
    </button>
  );
};

export default Button;
