import { readThread } from "../../../api/chat";
import { Thread } from "../../../types/Chat";

export const handleReadThread = async (thread: Thread) => {
    if (thread?.unread_count) {
        try {
            readThread(thread?.id);
        } catch (e) {
            console.log('Failed to unread thread!');
        }
    }
};