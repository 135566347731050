import React, { ReactChild } from 'react';
import './page.scss';

interface IPage {
  title?: any;
  children?: ReactChild | any;
}

const Page = ({ title, children }: IPage) => {
  return (
    <div className={'page'}>
      {title ? <h2 className={'page__title'}>{title}</h2> : ''}
      <div className={'page__content'}>{children}</div>
    </div>
  );
};

export default Page;
