import * as React from "react"

const BreifCase = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.706 6.21a3 3 0 0 0-3-3h-2a3 3 0 0 0-3 3M3.733 10.23l3.658 2.927c.487.39.73.584 1.002.723.24.123.497.213.762.267.298.062.61.062 1.234.062h4.633c.624 0 .936 0 1.234-.062.265-.054.521-.144.762-.267.271-.139.515-.334 1.002-.723l3.658-2.927m-17.945 0c-.027.468-.027 1.043-.027 1.778v4.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.31 1.311c.642.327 1.482.327 3.163.327h8.4c1.68 0 2.52 0 3.162-.326a3 3 0 0 0 1.31-1.312c.328-.641.328-1.481.328-3.162v-4.4c0-.735 0-1.31-.028-1.778m-17.945 0c.035-.6.116-1.023.3-1.383a3 3 0 0 1 1.31-1.312c.642-.327 1.482-.327 3.163-.327h8.4c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.31 1.312c.184.36.265.784.3 1.383"
      stroke="#131A29"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default BreifCase
