import moment from 'moment'

const getUTCtime = (typeDay, date) => {
  let utcDate: Date = new Date(date)

  let yyyy: number = utcDate.getFullYear()
  let mm: string = ("0" + (utcDate.getMonth() + 1)).slice(-2)
  let dd: string = ("0" + utcDate.getDate()).slice(-2)
  let fullUTCDate
  //Добавляем время начала дня
  if (typeDay === 'startDay') {
    fullUTCDate = yyyy + '-' + mm + '-' + dd + 'T00:00:00Z'
  }
  //Добавляем время конца дня
  if (typeDay === 'endDay') {
    fullUTCDate = yyyy + '-' + mm + '-' + dd + 'T23:59:59Z'
  }
  return moment(fullUTCDate).valueOf()
}

export default getUTCtime
