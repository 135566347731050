import React from 'react';
import './table.scss';
import { useTable, usePagination, useRowSelect } from 'react-table';
import Preloader from '../Preloader/Preloader';
import { FormattedMessage } from 'react-intl';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

const Table = ({ columns, data, getCellProps, perPage, showLoader, className }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: perPage || 10, data: data, manualPagination: true },
      stateReducer: (newState, action, prev, instance) => {
        if (perPage && newState.pageSize !== perPage) {
          newState.pageSize = perPage;
        }
      },
    },
    usePagination,
    // useRowSelect,
    // (hooks) => {
    // hooks.visibleColumns.push((columns) => [
    //   {
    //     id: 'selection',
    //     Header: ({ getToggleAllPageRowsSelectedProps }) => (
    //       <div>
    //         <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
    //       </div>
    //     ),
    //     Cell: ({ row }) => (
    //       <div>
    //         <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //       </div>
    //     ),
    //   },
    //   ...columns,
    // ]);
    // },
  );

  return (
    <>
      <div className={`table-container ${className}`}>
        {showLoader ? (
          <div className={'preloader'}>
            <div className={'preloader__inner'}>
              <Preloader />
            </div>
          </div>
        ) : (
          ''
        )}
        <table {...getTableProps()}>
          <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
          </thead>

          <tbody {...getTableBodyProps()} className={!page?.length ? 'empty' : ''}>
          {!page.length && !showLoader ? (
            <tr className={'empty-msg'}>
              <td>
                <FormattedMessage id="table.noItems" defaultMessage="No items" />
              </td>
            </tr>
          ) : null}
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={showLoader ? 'blur' : ''}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getCellProps(cell),
                      ])}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
