export const SELECT_DEFAULT_STYLES = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 2,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided, state) => {
    return {
      ...provided,
      padding: '10px 12px',
      fontSize: '14px',
      lineHeight: '22px',
      fontFamily: 'Inter',
      backgroundColor: state.isDisabled
        ? undefined
        : state.isSelected
        ? 'var(--main-light--color)'
        : state.isFocused
        ? 'var(--main-light--color)'
        : undefined,
      color: '#262626',
      ':active': {
        // ...styles[':active'],
        backgroundColor: !state.isDisabled ? (state.isSelected ? '#F9F1FF' : '#F9F1FF') : undefined,
      },
    };
  },
  control: (provided, state) => ({
    ...provided,
    padding: '12px',
    boxShadow: '1px solid #262626',
    border: state.isFocused ? '1px solid var(--main-color)' : '1px solid #c2c2c2',
    '&:hover': {
      border: state.isFocused ? '1px solid var(--main-color) !important' : '1px solid #c2c2c2',
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontSize: '14px',
      lineHeight: '22px',
      fontFamily: 'Inter',
    };
  },
};
