// Тут необходимо зарегистрировать константу для env переменной REACT_APP_DEFAULT_ICON
export const logo_default = require('./logo_default.svg');
export const nexgen = require('./nexgen.svg');
export const cyber = require('./cyber.svg');
export const golden = require('./golden.svg');
export const gho = require('./gho.svg');
export const ande = require('./andelogo.svg');
export const artis = require('./artis.svg');
export const vertex = require('./vertex.png');
export const worktrade = require('./WorkTrade.png');
export const financeadvice = require('./financeadvice.svg');
export const alaris = require('./alaris.svg');
export const maritime = require('./maritime.svg');
export const serica = require('./serica.svg');
export const vega = require('./vega.svg');
export const dws = require('./dws.svg');
export const romfor = require('./romfor.svg');
export const arow = require('./arow.svg');
export const inter = require('./inter.svg');
export const axa = require('./axa.svg');
export const colville = require('./colville.svg');
export const brsg = require('./brsg.svg');
export const hfi = require('./hfi.svg');
export const bs = require('./bs.svg');
export const kineo = require('./kineo.svg');
export const profinus = require('./profinus.svg');
export const aspect = require('./aspect.svg');
export const vfr = require('./vfr.svg');
