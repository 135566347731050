import React from 'react';
import { DashboardLayout } from '../pages/UserView/DashboardLayout';
import Finance from '../pages/Finance/Finance';
import SignIn from '../pages/Auth/SignIn/SignIn';
import SignUp from '../pages/Auth/SignUp/SignUp';
import ResetPassword from '../pages/Auth/ResetPassword/ResetPassword';
import Accounts from '../pages/Trading/Accounts/Accounts';
import History from '../pages/Trading/History/History';
import Referral from '../pages/Referral/Referral';
import Personal from '../pages/MyProfile/Personal/Personal';
import VerifyEmail from '../pages/Auth/VerifyEmail/VerifyEmail';
import ForgotPassword from '../pages/Auth/ForgotPassword/ForgotPassword';
import ChangePassword from '../pages/MyProfile/ChangePassword/ChangePassword';
import PrivateErrorPage from '../pages/PrivateErrorPage/PrivateErrorPage';

import OpenedPositions from '../pages/Trading/OpenedPositions/OpenedPositions';
import Deals from '../pages/Trading/Deals/Deals';
import MetaTrader from '../pages/MetaTrader/MetaTrader';
import Documents from '../pages/Documents/Documents';
import Cryptocurrency from '../pages/Finance/Cryptocurrency/Cryptocurrency';
import BankTransfersStatuses from '../pages/Finance/PaymentSystemStatuses/PaymentSystemStatuses';
import PaymentsSuccess from '../pages/Finance/PaymentSystemStatuses/Payment/PaymentsSuccess';
import PaymentsError from '../pages/Finance/PaymentSystemStatuses/Payment/PaymentsError';
import PaymentForm from '../pages/Finance/PaymentForm/PaymentForm';
import ChatPage from '../pages/Chat/Chat';
import AccessRestricted from 'pages/AccessRestricted/AccessRestricted';

interface IRoutes {
  id: string;
  path: string;
  component: JSX.Element;
  title: string;
  permission: string;
}

export const ROUTES: IRoutes[] = [
  {
    id: 'SIGN_IN',
    path: '/',
    component: <SignIn />,
    title: 'Авторизация',
    permission: 'all',
  },
  {
    id: 'SIGN_UP',
    path: '/sign-up',
    component: <SignUp />,
    title: 'Регистрация',
    permission: 'all',
  },
  {
    id: 'SIGN_UP',
    path: '/partner',
    component: <SignUp />,
    title: 'Регистрация',
    permission: 'all',
  },
  {
    id: 'RESET_PASSWORD',
    path: '/password/reset/:email/:code',
    component: <ResetPassword />,
    title: 'Сброс пароля',
    permission: 'all',
  },
  {
    id: 'FORGOT_PASSWORD',
    path: '/forgot-password',
    component: <ForgotPassword />,
    title: 'Востановить пароль',
    permission: 'all',
  },
  {
    id: 'VERIFY_MAIL',
    path: '/verify/:email/:code',
    component: <VerifyEmail />,
    title: 'Verify Email',
    permission: 'all',
  },
  {
    id: 'ACCESS_DENIED',
    path: '/access-restricted',
    component: <AccessRestricted />,
    title: 'Access restricted',
    permission: 'all',
  },
  {
    id: 'DEFAULT_PAGE',
    path: '*',
    component: <SignIn />,
    title: 'Страница не найдена',
    permission: 'all',
  },
];

export const PRIVATE_ROUTES: IRoutes[] = [
  {
    id: 'FINANCE',
    path: '/finance',
    component: <Finance />,
    title: 'finance',
    permission: 'all',
  },
  {
    id: 'ACCOUNTS',
    path: '/accounts',
    component: <Accounts />,
    title: 'accounts',
    permission: 'all',
  },
  {
    id: 'HISTORY',
    path: '/history',
    component: <History />,
    title: 'history',
    permission: 'all',
  },
  {
    id: 'OpenedPositions',
    path: '/opened-positions',
    component: <OpenedPositions />,
    title: 'OpenedPositions',
    permission: 'all',
  },
  {
    id: 'Deals',
    path: '/deals',
    component: <Deals />,
    title: 'Deals',
    permission: 'all',
  },
  {
    id: 'DOCUMENTS',
    path: '/documents',
    component: <Documents />,
    title: 'documents',
    permission: 'all',
  },
  {
    id: 'REFERRAL',
    path: '/referral',
    component: <Referral />,
    title: 'referral',
    permission: 'all',
  },
  {
    id: 'PERSONAL',
    path: '/personal',
    component: <Personal />,
    title: 'personal',
    permission: 'all',
  },
  {
    id: 'CHANGE_PASSWORD',
    path: '/change-password',
    component: <ChangePassword />,
    title: 'change password',
    permission: 'all',
  },
  {
    id: 'HOME_PAGE',
    path: '/dashboard',
    component: <DashboardLayout />,
    title: 'Личный кабинет',
    permission: 'all',
  },
  {
    id: 'CRYPTOCURRENCY',
    path: '/cryptocurrency',
    component: <Cryptocurrency />,
    title: 'Cryptocurrency',
    permission: 'all',
  },
  {
    id: 'BANK WIRE STATUSES',
    path: '/wire-statuses',
    component: <BankTransfersStatuses />,
    title: 'Bank wire statuses',
    permission: 'all',
  },
  {
    id: 'SUCCESS TRANSFERS',
    path: '/payment/success/:id',
    component: <PaymentsSuccess />,
    title: 'Wire Transfers',
    permission: 'all',
  },
  {
    id: 'SUCCESS TRANSFERS NOID',
    path: '/payment/success',
    component: <PaymentsSuccess />,
    title: 'Wire Transfers',
    permission: 'all',
  },
  {
    id: 'PAYMENT FORM',
    path: '/payment/form',
    component: <PaymentForm />,
    title: 'Payment Form',
    permission: 'all',
  },
  {
    id: 'ERROR TRANSFERS',
    path: '/payment/fail/:id',
    component: <PaymentsError />,
    title: 'Wire Transfers',
    permission: 'all',
  },
  {
    id: 'ERROR TRANSFERS NO ID',
    path: '/payment/fail',
    component: <PaymentsError />,
    title: 'Wire Transfers',
    permission: 'all',
  },
  {
    id: 'META_TRADER_PAGE',
    path: '/meta-trader',
    component: <MetaTrader />,
    title: 'Trading Platform',
    permission: 'all',
  },
  {
    id: 'ERROR_PAGE',
    path: '*',
    component: <PrivateErrorPage />,
    title: 'Страница не найдена',
    permission: 'all',
  },
];
