import React from 'react';
import {
  Gear,
  CaretRight,
  Download,
} from 'phosphor-react';
import { FormattedMessage } from 'react-intl';
import { BreifCase, CreditCardSvg, DiagramSvg, UserCheck } from "../components/ui/Icons";

export const topMenuItems = [
  {
    title: <FormattedMessage id="menu.finance" defaultMessage="Finance" />,
    itemId: '#2',
    elemBefore: () => <CreditCardSvg />,
    subNav: [
      {
        title: <FormattedMessage id="menu.payments" defaultMessage="Payments" />,
        itemId: '/finance',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: <FormattedMessage id="menu.transactionHistory" defaultMessage="Transaction history" />,
        itemId: '/cryptocurrency',
        elemBefore: () => <CaretRight size={15} />,
      },
    ]
  },
  {
    title: <FormattedMessage id="menu.trading" defaultMessage="Trading" />,
    itemId: '/trading',
    elemBefore: () => <DiagramSvg />,
    subNav: [
      {
        title: <FormattedMessage id="menu.accounts" defaultMessage="Accounts" />,
        itemId: '/accounts',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: <FormattedMessage id="menu.history" defaultMessage="Deals" />,
        itemId: '/history',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: <FormattedMessage id="menu.openedPositions" defaultMessage="Opened Positions" />,
        itemId: '/opened-positions',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: <FormattedMessage id="menu.deals" defaultMessage="Deals" />,
        itemId: '/deals',
        elemBefore: () => <CaretRight size={15} />,
      },
    ],
  },
  {
    title: <FormattedMessage id="menu.documents" defaultMessage="Documents" />,
    itemId: '/documents',
    elemBefore: () => <BreifCase />,
  },
  {
    title: <FormattedMessage id="menu.profile" defaultMessage="My profile" />,
    itemId: '/profile',
    elemBefore: () => <UserCheck />,
    subNav: [
      {
        title: <FormattedMessage id="menu.personal" defaultMessage="Personal info" />,
        itemId: '/personal',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: <FormattedMessage id="menu.changePassword" defaultMessage="Change password" />,
        itemId: '/change-password',
        elemBefore: () => <CaretRight size={15} />,
      },
    ],
  },
  {
    title: <FormattedMessage id="menu.metaTrader" defaultMessage="Trading Platform" />,
    itemId: '/meta-trader',
    elemBefore: () => <Download size={25} weight='bold' color='#444'/>,
  },
];

export const topMenuItemsWithPayments = [
  {
    title: <FormattedMessage id="menu.finance" defaultMessage="Finance" />,
    itemId: '#2',
    elemBefore: () => <CreditCardSvg />,
    subNav: [
      {
        title: <FormattedMessage id="menu.payments" defaultMessage="Payments" />,
        itemId: '/finance',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: <FormattedMessage id="menu.transactionHistory" defaultMessage="Transaction history" />,
        itemId: '/cryptocurrency',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: <FormattedMessage id="menu.paymentSystemStatuses" defaultMessage="Payment system statuses" />,
        itemId: '/wire-statuses',
        elemBefore: () => <CaretRight size={15} />,
      },
    ]
  },
  {
    title: <FormattedMessage id="menu.trading" defaultMessage="Trading" />,
    itemId: '/trading',
    elemBefore: () => <DiagramSvg />,
    subNav: [
      {
        title: <FormattedMessage id="menu.accounts" defaultMessage="Accounts" />,
        itemId: '/accounts',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: <FormattedMessage id="menu.history" defaultMessage="Deals" />,
        itemId: '/history',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: <FormattedMessage id="menu.openedPositions" defaultMessage="Opened Positions" />,
        itemId: '/opened-positions',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: <FormattedMessage id="menu.deals" defaultMessage="Deals" />,
        itemId: '/deals',
        elemBefore: () => <CaretRight size={15} />,
      },
    ],
  },
  {
    title: <FormattedMessage id="menu.documents" defaultMessage="Documents" />,
    itemId: '/documents',
    elemBefore: () => <BreifCase />,
  },
  {
    title: <FormattedMessage id="menu.profile" defaultMessage="My profile" />,
    itemId: '/profile',
    elemBefore: () => <UserCheck />,
    subNav: [
      {
        title: <FormattedMessage id="menu.personal" defaultMessage="Personal info" />,
        itemId: '/personal',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: <FormattedMessage id="menu.changePassword" defaultMessage="Change password" />,
        itemId: '/change-password',
        elemBefore: () => <CaretRight size={15} />,
      },
    ],
  },
  {
    title: <FormattedMessage id="menu.metaTrader" defaultMessage="Trading Platform" />,
    itemId: '/meta-trader',
    elemBefore: () => <Download size={25} weight='bold' color='#444'/>,
  },
];

export const bottomMenuitems = [
  {
    title: 'Settings',
    itemId: '/settings',
    elemBefore: () => <Gear size={24} />,
  },
];

export const MAIN_MENU_TYPE = {
  SIDE_MENU: 'side_menu',
  BURGER: 'burger_menu',
  SIDE_MENU_SEMI_BIG: 'side_menu_semi_big',
};
