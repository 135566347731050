import React from 'react';
import './tooltip.scss';
import ReactTooltip from 'react-tooltip';

const Tooltip = ({ params }) => {
  const id = params.dataFor + (Math.random() * 100000000).toFixed().toString();

  return (
    <div className={'tooltip'}>
      <span data-tip data-for={id} data-event="click">
        <svg className={'tooltip__icon'} width="18" height="18">
          <use href="#ic_question"></use>
        </svg>
      </span>
      <ReactTooltip
        id={id}
        globalEventOff={params.dataEvent ?? 'click'}
        delayHide={params.delayHide ?? 1000}
      >
        {params.tooltipText}
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;
