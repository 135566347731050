import { useIntl } from 'react-intl';
import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import './password-popup.scss';
import Button from '../Button/Button';
import { Key } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import PasswordInput from '../PasswordInput';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { changeTradingAccountPassword } from '../../../api/trading-account';
import { PASSWORD_INPUT_VALIDATION } from '../../../constants/validators';
import PopupTemplate from '../PopupTemplate';
import LoaderButton from '../LoaderButton';
import { FormattedMessage } from 'react-intl';

const PasswordPopup = ({ trigger, tradingAccount, onCloseModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const new_password = watch('password');

  const closeModal = (closeModal) => {
    reset();
    closeModal();
    onCloseModal();
  };

  const onSubmit = async (close, data) => {
    setIsLoading(true);

    try {
      setIsLoading(false);
      const res = await changeTradingAccountPassword(tradingAccount.id, data);
      NotificationManager.success(
        intl.formatMessage({ id: 'password.reset.alert.text' }),
        'Success',
        5000,
      );
      closeModal(close);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Popup
        modal
        trigger={trigger}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              <FormattedMessage
                id="page.trading.popup.changePassword.title"
                defaultMessage="Change trading account password"
              />
            }
            leftContent={
              <div className={'info'}>
                <Key size={100} />
              </div>
            }
            rightContent={
              <div className={'content'}>
                <div className={'content-title'}>
                  <span>
                    <FormattedMessage
                      id="page.trading.popup.changePassword.for"
                      defaultMessage="Change password for"
                    />{' '}
                    - {tradingAccount.trading_account}
                    {tradingAccount.name && ` (${tradingAccount.name})`}
                  </span>
                </div>
                <div className={'content-form'}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <div>
                      <PasswordInput
                        name="password"
                        type="password"
                        placeholder={intl.formatMessage({
                          id: 'page.trading.popup.changePassword.field.name.newPassword',
                          defaultMessage: 'New Password',
                        })}
                        label={
                          <FormattedMessage
                            id="page.trading.popup.changePassword.field.name.newPassword"
                            defaultMessage="New Password"
                          />
                        }
                        control={control}
                        rules={{
                          required: (
                            <FormattedMessage
                              id="form.error.passwordMessage_7"
                              defaultMessage="Password field is required"
                            />
                          ),
                          validate: PASSWORD_INPUT_VALIDATION,
                        }}
                        errors={errors.password}
                      />
                    </div>
                    <div>
                      <PasswordInput
                        name="password_confirmation"
                        type="password"
                        placeholder={intl.formatMessage({
                          id: 'page.trading.popup.changePassword.field.name.confirmNewPassword',
                          defaultMessage: 'Confirm New Password',
                        })}
                        label={intl.formatMessage({
                          id: 'page.trading.popup.changePassword.field.name.confirmNewPassword',
                          defaultMessage: 'Confirm New Password',
                        })}
                        control={control}
                        rules={{
                          required: (
                            <FormattedMessage
                              id="form.error.passwordMessage_7"
                              defaultMessage="Password field is required"
                            />
                          ),
                          validate: {
                            ...PASSWORD_INPUT_VALIDATION,
                            compare: (value) =>
                              value === new_password || (
                                <FormattedMessage
                                  id="form.error.passwordMessage_8"
                                  defaultMessage="The password confirmation does not match."
                                />
                              ),
                          },
                        }}
                        errors={errors.password_confirmation}
                      />
                    </div>
                    <div className={'content-controls'}>
                      <div className={'controls__buttons'}>
                        <Button
                          buttonText={intl.formatMessage({
                            id: 'page.trading.popup.changePassword.btn.cancel',
                            defaultMessage: 'Cancel',
                          })}
                          buttonType="secondary"
                          size="big"
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <LoaderButton
                          buttonType="primary"
                          size="big"
                          showSpinner={isLoading}
                          disabled={isLoading}
                          buttonText={intl.formatMessage({
                            id: 'page.trading.popup.changePassword.btn.change',
                            defaultMessage: 'Change password',
                          })}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default PasswordPopup;
