import React from 'react';
import { ChatCircleDots } from 'phosphor-react';
import { ChatBadge } from '../ChatBadge/ChatBadge';
import './ChatIcon.scss';

export const ChatIcon = ({ messagesCount }) => {
    return (
        <div className='chat-icon'>
            <ChatCircleDots size={25} />
            { messagesCount ? <ChatBadge count={messagesCount}/> : null }
        </div>
    )
}