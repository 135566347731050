import React, { useEffect, useRef, useState } from 'react';

import './datepicker-range.scss';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import FormInput from '../FormInput';
import { CalendarBlank } from 'phosphor-react';
import { useOnClickOutside } from '../../../utils/useClickOutside';
import moment from 'moment';
import { useController } from 'react-hook-form';
import './customStyle.scss';

const DatePickerRange = (props) => {
  const [inputValue, setInputValue] = useState();
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const contentRef = useRef(null);
  const { field } = useController(props);

  const handleSelect = ({ selection }) => {
    setRange([selection]);
    const from = moment(selection.startDate).format('Y-MM-DD');
    const to = moment(selection.endDate).format('Y-MM-DD');
    field.onChange({ from, to });
    props.onChange?.({ from, to });
    if (props.handleChange) {
      props.handleChange({ from: from, to: to });
    }
  };

  useOnClickOutside(contentRef, () => {
    setShowDatePicker(false);
  });

  useEffect(() => {
    if (!field.value) {
      setInputValue('');
      setRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ]);
      return;
    }

    setInputValue(`${field?.value?.from} - ${field?.value?.to}`);
    setRange([
      {
        startDate: new Date(field?.value?.from),
        endDate: new Date(field?.value?.to),
        key: 'selection',
      },
    ]);
  }, [field.value]);

  return (
    <div className='date-picker__range'>
      <div className={'date-range'}>
        <div className={'date-input'}>
          <FormInput
            name={props.name}
            type="text"
            value={inputValue}
            onChange={() => {
              field.onChange(null);
              props.onChange?.(null);
            }}
            autoFocus={props.autoFocus}
            autoComplete={'none'}
            placeholder={props.placeholder}
            label={props.label}
            control={props.control}
            rules={props.rules}
            errors={props.errors}
            onFocus={() => setShowDatePicker(true)}
          />
          <span className={'icon'}>
          <CalendarBlank size={24} />
        </span>
        </div>
        {showDatePicker && (
          <div className={'date-range'} ref={contentRef}>
            <DateRange
              editableDateInputs={false}
              moveRangeOnFirstSelection={false}
              ranges={range}
              onChange={handleSelect}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DatePickerRange;
