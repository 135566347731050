import React from 'react';
import './Bank-transfers-statuses-table.scss';
import { makeTableData } from '../../../../helpers/table.helper';
import Table from '../Table';
import { TABLE_WIRE_TRANSFERS_COLUMNS_NAMES } from '../../../../constants/table';
import moment from 'moment/moment';
import PaymentPopupErrors from '../../../../pages/Finance/PaymentSystemStatuses/PaymentPopupErrors';
import Button from '../../Button/Button';

const BankTransfersStatusesTable = ({ data, perPage, showLoader }) => {
  const columns = React.useMemo(() => TABLE_WIRE_TRANSFERS_COLUMNS_NAMES, []);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item) => {
    const { amount, created_at, currency, details, id, status, subRows, trading_account, updated_at } = item;

    const currentDetails = Array.isArray(details) && details?.map(item => Object.values(item)).flat();

    return {
      AccName: (
        <span className={'account-name'}>
          {trading_account[0]?.name}
          <span className={trading_account[0]?.status}>{trading_account[0]?.status || '---'}</span>
        </span>
      ),
      tradingAcc: (
        <span className={'trading_account'}>
          {trading_account[0]?.trading_account}
        </span>
      ),
      amount: (
        <span>{amount + ' ' + currency || ' '}</span>
      ),
      dateTime: (
        <>
          <span>{moment.unix(created_at).format('DD.MM.YYYY')}</span> <br />
          <span>{moment.unix(created_at).format('HH:mm')}</span>
        </>
      ),
      status: (
        <div className={'wire-status'}>
          <span className={`${status?.toLowerCase()} wire-status__item`}>{status}</span><br />
          <span>
            on {moment.unix(updated_at).format('DD.MM.YYYY')}
            &nbsp;{moment.unix(updated_at).format('HH:mm')}
          </span>
          {currentDetails &&
            <PaymentPopupErrors
              triggerBtn={
                <Button
                  style={{ margin: 0 }}
                  buttonText='Show errors'
                  buttonType='outline'
                />
              }
              errorsDetails={currentDetails}
            />
          }
        </div>
      ),
    };
  });

  return (
    <Table
      columns={columns}
      data={prepearedTableData}
      perPage={perPage}
      showLoader={showLoader}
      className={'wire-transfers-table'}
      getCellProps={(cellInfo) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
          },
        };
      }}
    />
  );
};

export default BankTransfersStatusesTable;
