import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import './new-account-popup.scss';
import Button from '../Button/Button';
import { UserCirclePlus, X } from 'phosphor-react';
import { CURRENCY } from '../../../constants/deposit';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FormInput from '../FormInput';
import Select from '../Select/Select';
import Preloader from '../Preloader/Preloader';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { notify } from '../../../utils/notify';
import { createTradingAccount } from '../../../api/trading-account';
import PopupTemplate from '../PopupTemplate';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import CheckBox from '../CheckBox';
import { useSelector } from 'react-redux';
import { getPaymentsEnableWebTrader } from '../../../utils/selectors';

const NewAccountPopup = ({ trigger, onClose }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const ENABLE_WT = useSelector(getPaymentsEnableWebTrader)

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };

  const onSubmit = async (close, data) => {
    setIsLoading(true);

    const sendData = {
      ...data,
      amount_type: data.amount_type.value,
    }

    if (ENABLE_WT) {
      sendData['demo'] = data.demo ? 1 : 0;
    }

    try {
      const res = await createTradingAccount(sendData);
      NotificationManager.success(
        intl.formatMessage({ id: 'trading.account.created.text' }), 
        'Success', 
        5000
      );
      onClose(res);
      closeModal(close);
    } catch (err) {
      notify({
        type: 'error',
        message: err.response,
        timeOut: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const options = Object.keys(CURRENCY).map((item) => ({
    value: item,
    label: item,
  }));

  return (
    <Popup
      modal
      trigger={trigger}
      closeOnEscape
      repositionOnResize
      lockScroll
      closeOnDocumentClick
    >
      {(close) => (
        <PopupTemplate
          dismissModal={closeModal.bind(undefined, close)}
          headerTitle={
            <FormattedMessage id="newAccountPopup.title" defaultMessage="Create trading account" />
          }
          leftContent={
            <div className={'info'}>
              <UserCirclePlus className={'document-types__close-btn'} size={150} />
            </div>
          }
          rightContent={
            <div className={'content-new__account'}>
              <div className={'content-new__account-title'}>
                <span>
                  <FormattedMessage
                    id="newAccountPopup.descr"
                    defaultMessage="Enter account information"
                  />
                </span>
              </div>
              <div className={'content-new__account-form'}>
                <form
                  className={'form'}
                  onSubmit={handleSubmit(onSubmit.bind(undefined, close))}
                >
                  {isLoading ? <Preloader /> : null}
                  <Container className={isLoading ? 'p-0 blur' : 'p-0'}>
                    <Row className={'form__line'}>
                      <Col md={12}>
                        <FormInput
                          name="account_name"
                          type="text"
                          placeholder={intl.formatMessage({
                            id: 'newAccountPopup.field.accountName.title',
                            defaultMessage: 'Account Name',
                          })}
                          label={
                            <FormattedMessage
                              id="newAccountPopup.field.accountName.title"
                              defaultMessage="Account Name:"
                            />
                          }
                          control={control}
                          rules={{
                            required: (
                              <FormattedMessage
                                id="form.error.requiredFieldnoName"
                                defaultMessage="Field is required"
                              />
                            ),
                          }}
                          errors={errors.account_name}
                        />
                      </Col>
                    </Row>
                    <Row className={'form__line'}>
                      <Col md={12}>
                        <Select
                          name="amount_type"
                          label={
                            <FormattedMessage
                              id="newAccountPopup.field.currency.title"
                              defaultMessage="Currency"
                            />
                          }
                          placeholder={
                            <FormattedMessage
                              id="newAccountPopup.field.currency.title"
                              defaultMessage="Currency"
                            />
                          }
                          defaultValue={options?.[0]}
                          control={control}
                          rules={{
                            required: (
                              <FormattedMessage
                                id="form.error.requiredFieldnoName"
                                defaultMessage="Field is required"
                              />
                            ),
                          }}
                          errors={errors.amount_type}
                          options={options}
                        />
                      </Col>
                      {
                        ENABLE_WT ?
                          <Row>
                            <Col md={12}>
                              <CheckBox
                                control={control}
                                name='demo'
                                type='checkbox'
                                label={
                                  <FormattedMessage
                                    id="newAccountPopup.demoCheckbox"
                                    defaultMessage="Demo account"
                                  />
                                }
                                id='demo'
                              />
                            </Col>
                          </Row>
                          : null
                      }
                    </Row>

                    <div className={'content-new__account-controls'}>
                      <div className={'controls__buttons'}>
                        <Button
                          buttonText={
                            <FormattedMessage
                              id="newAccountPopup.cancelBtn"
                              defaultMessage="Create"
                            />
                          }
                          buttonType="secondary"
                          type="reset"
                          size="big"
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <Button
                          type="submit"
                          buttonText={
                            <FormattedMessage id="newAccountPopup.btn" defaultMessage="Create" />
                          }
                          size="big"
                          // disabled={true}
                        />
                      </div>
                    </div>
                  </Container>
                </form>
              </div>
            </div>
          }
        />
      )}
    </Popup>
  );
};

export default NewAccountPopup;
