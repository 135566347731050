import React, { useState } from 'react';
import './passwordInput.scss';
import { useController } from 'react-hook-form';
import Tooltip from '../Tooltip/Tooltip';
import { Eye, EyeSlash } from 'phosphor-react';

const PasswordInput = (props) => {
  const { field } = useController(props);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={'password_input'}>
      <div className={'label-wrap'}>
        {props.label && (
          <label htmlFor={props.id} className={props.errors ? 'label error' : 'label'}>
            {props.label}
            {props.rules && props.rules.required ? <span className={'required'}>*</span> : ''}
          </label>
        )}
        {props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}
      </div>
      <div className={props.errors ? 'input-wrap error' : 'input-wrap'}>
        <input
          {...field}
          className={props.errors ? 'input error' : 'input'}
          type={showPassword ? 'text' : 'password'}
          autoComplete={props.autoComplete ? props.autoComplete : ''}
          placeholder={props.placeholder}
          value={field.value}
        />
        <span className={'input-icon'}>
          {!showPassword ? (
            <Eye size={24} onClick={handleShowPassword} />
          ) : (
            <EyeSlash size={24} onClick={handleShowPassword} />
          )}
        </span>
        {props.errors && <p className={'errorName'}>{props.errors.message}</p>}
      </div>
    </div>
  );
};

export default PasswordInput;
