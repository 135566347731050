import * as React from 'react';

const LogOutSvg = (props) => (
  <svg
    width={25}
    height={25}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.706 22.21a1 1 0 1 0 0-2v2Zm0-18a1 1 0 1 0 0-2v2Zm4.293 11.292a1 1 0 0 0 1.414 1.415l-1.415-1.415Zm4.707-3.293.707.708a1 1 0 0 0 0-1.415l-.707.707Zm-3.293-4.707a1 1 0 1 0-1.415 1.415l1.415-1.415Zm-6.707 3.708a1 1 0 1 0 0 2v-2Zm1 9a8 8 0 0 1-8-8h-2c0 5.522 4.477 10 10 10v-2Zm-8-8a8 8 0 0 1 8-8v-2c-5.523 0-10 4.477-10 10h2Zm13.707 4.707 4-4-1.415-1.415-4 4 1.415 1.415Zm4-5.415-4-4-1.415 1.415 4 4 1.415-1.415ZM11.706 13.21h10v-2h-10v2Z'
      fill='#131A29'
    />
  </svg>
);

export default LogOutSvg;
