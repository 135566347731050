import React, { useEffect, useState } from 'react';
import './header.scss';
import AuthUserInfo from '../AuthUserInfo/AuthUserInfo';
import LangSwitcher from '../LangSwitcher/LangSwitcher';
import Button from '../Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { MAIN_MENU_TYPE } from '../../../constants/menuItems';
import Menu from '../Menu';
import FinancePopup from '../FinancePopup';
import { POPUP_TYPE } from '../../../constants/popup';
import { FormattedMessage } from 'react-intl';
import { getTradingAccounts } from '../../../api/trading-account';
import { getHeaderInfo } from '../../../api/myProfile';
import { setErrorBalance, setHeaderInfo } from '../../../store/slice/headerInfoSlice';
import { setNeedToUpdate } from '../../../store/slice/transactionSlice';
import { TRADING_ACCOUNT_STATUS } from '../../../constants/trading-account-status';
import ErrorPopup from '../ErrorPopup';
import { WarningCircle } from 'phosphor-react';
import { notify } from '../../../utils/notify';
import api from '../../../api/api';

const Header = ({ isMobile }) => {
  const headerInfo = useSelector((state) => state.headerInfo.headerInfo);
  const errorMessage = useSelector(state => state.headerInfo.errorBalance)
  const [tradingAccounts, setTradingAccounts] = useState();
  const dispatch = useDispatch();
  const isNeedToUpdateAccData = useSelector((state) => state.transactions.needToUpdate);

  let balance;
  let credit_balance;

  if (headerInfo && headerInfo.balance) {
    const currencyArr = Object.keys(headerInfo.balance);
    balance = currencyArr.map((item, index) => <div key={index}>{`${item}: ${headerInfo.balance[item].total}`}</div>);
    credit_balance = currencyArr.map((item, index) => <div
      key={index}>{`${item}: ${headerInfo.balance[item].credit}`}</div>);
  }

  useEffect(() => {
    updateAccounts();
    updateHeaderInfo();
  }, []);

  useEffect(() => {
    if (!isNeedToUpdateAccData.value) {
      return;
    }
    updateAccounts();
    updateHeaderInfo();
  }, [isNeedToUpdateAccData]);

  // api.interceptors.response.use(
  //   (response) => {},
  //   (error) => {
  //     const { config, data, status, statusText } = error.response;
  //     if (config.url === '/api/v1/user/header-info') {
  //       dispatch(setErrorBalance({
  //         message: data.message,
  //         status: status,
  //         statusText: statusText,
  //       }))
  //     }
  //   },
  // );

  const updateHeaderInfo = () => {
    getHeaderInfo()
      .then((res) => {
        const info = res.data.data;
        dispatch(setErrorBalance(''))
        dispatch(setHeaderInfo(info));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setErrorBalance(err))
      });
  };

  const updateAccounts = () => {
    getTradingAccounts(`per_page=100&status=${TRADING_ACCOUNT_STATUS.ACTIVE}`)
      .then((res) => {
        const accList = res.data.data;
        setTradingAccounts(accList);
      })
      .catch((err) => console.log(err));
  };

  const renderDefaultMenu = () => (
    <header className={'header'}>
      <div className={'header__container'}>
        {/* <div className={'header__col'}>
          <Search placeholder="Search" />
        </div> */}
        <div className={'header__col'}>
          <AuthUserInfo
            userName={headerInfo?.user?.first_name + ' ' + headerInfo?.user?.last_name}
          />
        </div>
        <div className={'header__col'}>
          <div className={'balance'}>
            <div className={'balance__title'}>
              <FormattedMessage id='header.balance' defaultMessage='Balance' />
            </div>
            <div className={'balance__count'}>{balance}</div>
          </div>
          <div className={'balance'}>
            <div className={'balance__title'}>
              <FormattedMessage id='header.creditBalance' defaultMessage='Credit Balance' />
            </div>
            <div className={'balance__count'}>{credit_balance}</div>
          </div>

          {errorMessage && <ErrorPopup
            trigger={
              <WarningCircle
                size={32}
                color='#eb0000'
                cursor={'pointer'}
              />
            }
            messageError={errorMessage}
          />}

          <FinancePopup
            type={POPUP_TYPE.DEPOSIT}
            trigger={
              <Button
                buttonText={
                  <FormattedMessage id='page.finance.deposit.btn' defaultMessage='Deposit' />
                }
                size='big'
              />
            }
            onSuccess={() => {
              dispatch(setNeedToUpdate({ value: true }));
            }}
            accountsData={tradingAccounts}
          />
        </div>
        <div className={'header__col'}>
          <LangSwitcher />
        </div>
      </div>
    </header>
  );

  const renderMobilemenu = () => (
    <header className={`header ${isMobile ? 'mobile' : ''}`}>
      <div className={'header__container'}>
        <div className={'header__col'}>
          <Menu type={MAIN_MENU_TYPE.BURGER} />
        </div>
        <div className={'header__col'}>
          <AuthUserInfo
            userName={headerInfo?.user?.first_name + ' ' + headerInfo?.user?.last_name}
          />
          <div className={'hr'}></div>
          <div className={'balance'}>
            <div className={'balance__title'}>
              <FormattedMessage id='header.balance' defaultMessage='Balance' />
            </div>
            <div className={'balance__count'}>{balance}</div>
          </div>
          <div className={'balance'}>
            <div className={'balance__title'}>
              <FormattedMessage id='header.creditBalanceMobile' defaultMessage='Credit' />
            </div>
            <div className={'balance__count'}>{credit_balance}</div>
          </div>

          {errorMessage && <ErrorPopup
            trigger={
              <WarningCircle size={32} color='#eb0000' />
            }
            messageError={errorMessage}
          />}

        </div>
        <div className={'header__col'}>
          <LangSwitcher
            mobileStyles={{
              indicatorsContainer: {
                '> div': {
                  '& svg': {
                    fill: 'var(--white) !important',
                  },
                },
              },
              valueContainer: {
                color: 'var(--white) !important',
              },
            }}
          />
        </div>
      </div>
    </header>
  );

  return isMobile ? renderMobilemenu() : renderDefaultMenu();
};
export default Header;
