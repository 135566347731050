import classNames from 'classnames/bind';
import React from 'react';
import './controls-group-title.scss';


const ControlsGroupTitle = ({ children }) => {
    return (<h3 className={'title'}>{children}</h3>);
}

export default ControlsGroupTitle;
