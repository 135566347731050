import React from 'react';
import './transaction-table.scss';
import Button from '../../Button/Button';
import { makeTableData } from '../../../../helpers/table.helper';
import Table from '../Table';
import { withdrawCancel } from '../../../../api/deposit';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { notify } from '../../../../utils/notify';
import { TABLE_HEADER_COLUMNS_NAMES, TABLE_HEADER_COLUMNS_NAMES_WITH_PAYMENTS } from '../../../../constants/table';
import moment from 'moment';
import { changePaymentMethod } from '../../../../helpers/changePaymentMethod';
import { useAppSelector } from '../../../../store';
import { getPaymentsEnablePayment } from '../../../../utils/selectors';

const TransactionTable = ({ data, perPage, showLoader, modifyDataHandler }) => {
  const ENABLE_PAYMENT = useAppSelector(getPaymentsEnablePayment);
  const columns = React.useMemo(() => ENABLE_PAYMENT ? TABLE_HEADER_COLUMNS_NAMES_WITH_PAYMENTS :TABLE_HEADER_COLUMNS_NAMES, [ENABLE_PAYMENT]);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const cancelHandler = (item) => {
    withdrawCancel(item.id)
      .then((res) => {
        if (!res.errors) {
          modifyDataHandler((cell) => {
            if (cell.id === item.id) {
              cell.status = 'canceled';
            }
            return cell;
          });
          NotificationManager.success(`Transaction canceled`, 'Success', 3000);
        }
      })
      .catch((err) =>
        notify({
          type: 'error',
          message: err.response,
          timeOut: 5000,
        }),
      );
  };

  const prepearedTableData = tableData.map((item, pos) => {
    const {
      trading_account_id,
      demo,
      id,
      gateway,
      payment_method,
      type,
      amount,
      amount_type,
      created_at,
      status_created_date,
      transaction_id,
      status,
      acc_type,
    } = item;

    return {
      ...item,
      type: (
        <span className={`type ${type.toLowerCase()}`}>{type && type.replace('_', ' ')}</span>
      ),
      tradingAcc: (
        <span className={'trading_account'}>
          {demo === 1 && <><p style={{ color: 'blue' }}>DEMO</p>{' '}</>}{trading_account_id}
          <span>{acc_type ? acc_type : '---'}</span>
        </span>
      ),
      amount: (
        <span>
          {amount} {amount_type}
        </span>
      ),
      transaction: (
        <span className={'transaction__id'}>
          {transaction_id}
          {/* <span className={'live'}>{'live'}</span> */}
        </span>
      ),
      paymentMethod: (
        <span>
          {changePaymentMethod(payment_method)}
        </span>
      ),
      gateway: (
        <span>
          {gateway ? gateway : "---"}
        </span>
      ),
      dateTime: (
        <div className={'date-cell'}>
          <span>{moment.unix(created_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(created_at).format('HH:mm')}</span>
        </div>
      ),
      status: (
        <div className={'date-cell'}>
          <span className={`${status?.toLowerCase()} status`}>{status}</span>
          <span>
            on {moment.unix(status_created_date).format('DD.MM.YYYY')}
            &nbsp;{moment.unix(status_created_date).format('HH:mm')}
          </span>
          {status?.toLowerCase() === 'pending' && type === 'withdraw' ? (
            <Button
              style={{ margin: 0 }}
              buttonText='Cancel'
              buttonType='outline'
              onClick={cancelHandler.bind(undefined, item)}
            />
          ) : null}
        </div>
      ),
    };
  });

  return (
    <Table
      columns={columns}
      data={prepearedTableData}
      perPage={perPage}
      showLoader={showLoader}
      getCellProps={(cellInfo) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : 'var(--body-background-color)'}`,
          },
        };
      }}
    />
  );
};

export default TransactionTable;
