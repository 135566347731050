import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Select, { components } from 'react-select';
import { SELECT_DEFAULT_STYLES } from '../../../constants/select';
import './account-select.scss';
import { useIntl } from 'react-intl';


const AccountSelect = (props) => {
  const intl = useIntl();
  const { field } = useController(props);
  const [value, setValue] = useState();

  const balance = intl.formatMessage({
    id: 'header.balance',
    defaultMessage: 'Balance',
  });
  const {
    title,
    placeholder,
    disabled,
    accountsData,
    handleChange,
    defaultAccount,
    controlPadding,
    listHeight,
    required,
    ...register
  } = props;

  useEffect(() => {
    if (value) {
      const options = getOptions(accountsData);
      const index = options.findIndex((item) => item.value === value.value);
      if (index > -1) {
        setValue(options[index]);
        field.onChange(options[index]);
        handleChange(options[index]);
      }
    }
  }, [accountsData]);

  useEffect(() => setValue(field.value), [field.value]);


  const customStyles = {
    ...SELECT_DEFAULT_STYLES,
    control: (provided, state) => ({
      ...provided,
      padding: controlPadding || '12px',
      background: 'var(--body-background-color)',
      boxShadow: '1px solid #262626',
      border: '1px solid var(--border-background-color)',
        // state.isFocused ? '1px solid var(--main-color)' : '1px solid #c2c2c2',
      '&:hover': {
        border: '1px solid var(--main-color)',
        // border: state.isFocused ? '1px solid var(--main-color) !important' : '1px solid #c2c2c2',
      },
      minHeight: '48px',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      maxHeight: listHeight,
    }),
    input: (provided) => ({
      ...provided,
      color: 'transparent',
    })
  };

  const getOptions = () => {
    if (!accountsData) {
      return [];
    }
    return accountsData
      // comment line below when demo accounts are needed
      .filter(item => !(props.hideDemo && item.demo === 1))
      .map((item) => ({
        value: item.trading_account,
        data: item,
        label: (item.name || '[NO NAME]') + ' ID ' + item.trading_account.split('TA-').reverse()[0],
      }));
  };

  const getDefaultAcc = () => {
    if (defaultAccount) {
      return getOptions().find((item) => item.value === defaultAccount.trading_account);
    }
    return null;
  };

  const formatName = (name) => {
    if (!name) {
      return name;
    }
    return name.split('TA-').reverse()[0];
  };

  const ControlComponent = ({ children, ...props }) => {
    return (
      <div className={'select-component-account'}>
        <span className={'title'}>{title}{required && <span style={{ color: 'red' }}> *</span>}</span>
        <components.Control {...props} className={'select-component-account__control'}>
          {children}
        </components.Control>
      </div>
    );
  };

  const formatOptionLabel = ({ data }) => {
    if (!data) {
      return null;
    }
    return (
      <div className={'option_select-component'}>
        <span className={'option_select-component__title'}>
          {data.demo === 1 && <><span style={{ color: 'blue' }}>DEMO</span>{' '}</>}{data.name || '[NO NAME]'} - ID {data.trading_account}
          {/*<span className={`account__status ${data.status}`}>{data.type}</span>*/}
        </span>
        <div className={'option_select-component__subtitle'}>
          {balance}: {data.balance} {data.amount_type}
        </div>
      </div>
    );
  };

  return (
    <Select
      {...field}
      styles={customStyles}
      classNamePrefix="react-select"
      defaultValue={getDefaultAcc()}
      value={value}
      options={getOptions()}
      formatOptionLabel={formatOptionLabel || null}
      onChange={(e) => {
        field.onChange(e);
        setValue(e);
        handleChange?.(e);
      }}
      components={{ Control: ControlComponent, IndicatorSeparator: () => null }}
      placeholder={
        placeholder || <FormattedMessage id="placeholder.select" defaultMessage="Select" />
      }
      noOptionsMessage={() => intl.formatMessage({ id: 'select.no_options' })}
    />
  );
};

export default AccountSelect;
