import { createSlice } from '@reduxjs/toolkit';

export const headerInfoSlice = createSlice({
  name: 'headerInfo',
  initialState: {
    headerInfo: {
      user: {
        first_name: '',
        last_name: '',
      },
      balance: {
        EUR: { total: 'N/A', credit: 'N/A' },
        USD: { total: 'N/A', credit: 'N/A' },
      },
    },
    errorBalance: '',
  },
  reducers: {
    setHeaderInfo: (state, action) => {
      if (action.payload) {
        state.headerInfo = action.payload;
      }
    },
    setErrorBalance(state, action) {
      state.errorBalance = action.payload;
    },
  },
});

export const { setHeaderInfo, setErrorBalance } = headerInfoSlice.actions;

export default headerInfoSlice.reducer;
