import React from 'react';
import Popup from 'reactjs-popup';
import './errorPopup-popup.scss';
import Button from '../Button/Button';
import PopupTemplate from '../PopupTemplate';

const ErrorPopup = ({ trigger, messageError }) => {
  return (
    <>
      <Popup
        modal
        trigger={trigger}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            dismissModal={close}
            headerTitle={'Error balance'}
            rightContent={
              <div className={'content__policies'}>
                <div>
                  <div className={'text-wrapper'}>
                    <span className={'content__policies-text'}>
                      {messageError.message}
                    </span>
                  </div>
                  <div className={'content__policies-controls'}>
                    <div className={'controls__buttons'}>
                      <Button
                        buttonText={'OK'}
                        buttonType="primary"
                        size="big"
                        onClick={close}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default ErrorPopup;
