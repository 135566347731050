import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Select, { components } from 'react-select';
import { SELECT_DEFAULT_STYLES } from '../../../constants/select';
import './LKSelect.scss';

const LKSelect = (props) => {
  const intl = useIntl();
  const { field } = useController(props);
  const [value, setValue] = useState();

  const {
    title,
    placeholder,
    disabled,
    options,
    handleChange,
    defaultAccount,
    controlPadding,
    listHeight,
    required,
    ...register
  } = props;

  const customStyles = {
    ...SELECT_DEFAULT_STYLES,
    control: (provided, state) => ({
      ...provided,
      padding: controlPadding || '12px',
      background: 'var(--body-background-color)',
      boxShadow: '1px solid #262626',
      border: '1px solid var(--border-background-color)',
      // border: state.isFocused ? '1px solid var(--main-color)' : '1px solid #c2c2c2',
      '&:hover': {
        border: '1px solid var(--main-color)',
        // border: state.isFocused ? '1px solid var(--main-color) !important' : '1px solid #c2c2c2',
      },
      minHeight: '48px',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      maxHeight: listHeight,
    }),
    input: (provided) => ({
      ...provided,
      color: 'transparent',
    })
  };

  const getOptions = () => {
    if (!options) {
      return [];
    }
    return options.map(item => {
      return (
        {
          value: item.value,
          label: item.label,
        }
      )
    })
  };

  useEffect(() => setValue(field.value), [field.value]);

  const ControlComponent = ({ children, ...props }) => {
    return (
      <div className={'lk-select-component'}>
        {title ? <span className={'title'}>{title} {required && <span style={{ color: 'red' }}>*</span>}</span> : null}
        <components.Control {...props} className={'lk-select-component__control'}>
          {children}
        </components.Control>
      </div>
    );
  };

  const formatOptionLabel = (value) => {
    if (!value) {
      return null;
    }
    return (
      <div className={'lk-option'}>
        <span className={'lk-option__title'}>
          {value.value}
        </span>
      </div>
    );
  };

  return (
    <Select
      {...field}
      styles={customStyles}
      classNamePrefix="react-select"
      value={value}
      options={getOptions()}
      formatOptionLabel={formatOptionLabel || null}
      onChange={(e) => {
        field.onChange(e);
        setValue(e);
        handleChange && handleChange(e);
      }}
      components={{ Control: ControlComponent, IndicatorSeparator: () => null }}
      placeholder={
        placeholder || <FormattedMessage id="placeholder.select" defaultMessage="Select" />
      }
      noOptionsMessage={() => intl.formatMessage({ id: 'select.no_options' })}
    />
  );
};

export default LKSelect;
