const getCompanyName = (state) => state.companyName.companyName;
const getLang = (state) => state.langList.currentLang;
const getAuthorized = (state) => state.auth.isAuthorized;
const getLoading = (state) => state.auth.isLoading;
const getAuth = (state) => state.auth;
const getLogo = (state) => state.companyName.images.logo;
const getProperLogo = (state) => state.companyName.images.properLogo;
const getImageSignUp = (state) => state.companyName.images.signUp;
const getImageSignIn = (state) => state.companyName.images.signIn;
const getImageResetPassword = (state) => state.companyName.images.forgotPassword;
const needUpdateData = (state) => state.transactions.needToUpdate;
const selectAuth = (state) => state.auth;
const getPaymentsCurrency = (state) => state.payments.PAYMENT_CURRENCY;
const getPaymentsCurrencyKasha = (state) => state.payments.PAYMENT_CURRENCY_KASHA;
const getPaymentsEnablePayment = (state) => state.payments.ENABLE_PAYMENT;
const getEnableCreditCard = (state) => state.payments.ENABLE_CREDIT_CARD;
const getPaymentsGateways = (state) => state.payments.PAYMENT_GATEWAYS;
const getPaymentsEnableCCWithdraw = (state) => state.payments.ENABLE_CREDIT_CARD_WITHDRAW;
const getEnableBankCard = (state) => state.payments.ENABLE_BANK_CARD;
const getPaymentsEnableBCWithdraw = (state) => state.payments.ENABLE_BANK_CARD_WITHDRAW;

const getTransactionUpdate = (state) => state.transactions.needToUpdate;
const getPaymentsEnableWebTrader = (state) => state.payments.ENABLE_WEB_TRADER;
const getTranslations = (state) => state.payments.TRANSLATE;

const selectIsBlockedIpCountry = (state) => state.auth.isBlockedIpCountry;

export {
  getAuthorized,
  getLang,
  getCompanyName,
  getAuth,
  getLogo,
  getProperLogo,
  getImageSignUp,
  getImageSignIn,
  getPaymentsCurrency,
  getImageResetPassword,
  getPaymentsEnablePayment,
  getLoading,
  selectAuth,
  needUpdateData,
  getTransactionUpdate,
  getPaymentsGateways,
  getPaymentsCurrencyKasha,
  getPaymentsEnableWebTrader,
  getTranslations,
  getEnableCreditCard,
  selectIsBlockedIpCountry,
  getPaymentsEnableCCWithdraw,
  getEnableBankCard,
  getPaymentsEnableBCWithdraw,
};
