let cachedApiBaseUrl = '';
let fetchingApiUrlPromise: Promise<string> | null = null;

export const getApiUrl = async () => {
  if (cachedApiBaseUrl) return cachedApiBaseUrl;

  if (!fetchingApiUrlPromise) {
    fetchingApiUrlPromise = (async () => {
      const response = await fetch('/urls.json');
      const json = await response.json();
      const { lk, crm } = json[process.env.REACT_APP_COMPANY_KEY];
      cachedApiBaseUrl = lk;

      window.apiUrl = lk;
      window.crmApiUrl = crm;

      return cachedApiBaseUrl;
    })();
  }

  return fetchingApiUrlPromise;
};
