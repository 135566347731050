import React from "react";
import { DashboardLayout } from "../UserView/DashboardLayout";
import { useSelector } from "react-redux";
import {
    selectChatAuth,
    selectChatConnectionStatus,
    selectChatToken
} from "../../store/slice/chat/chatSlice";
import { ChatDialog } from "./components/ChatDialog/ChatDialog";
import { useChatOpen } from "./helpers/useChatOpen";
import { usePageTitle } from "../../hooks/usePageTitle";
import './Chat.scss';

const ChatPage = () => {
    const token = useSelector(selectChatToken);
    const userLoaded = useSelector(selectChatAuth);
    const connectionEstablished = useSelector(selectChatConnectionStatus);

    useChatOpen();
    usePageTitle('Joker chat');

    return (
        <DashboardLayout>
            <div className="chat">
                {!connectionEstablished ? <div className='connection-status'>Connection issues...</div> : null}
                {(token && userLoaded) ? <div className="chat-content"><ChatDialog /></div> : null}
            </div>
        </DashboardLayout>
    )
};

export default ChatPage;