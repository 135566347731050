import React, { useEffect } from 'react';
import './formInput.scss';
import { useController } from 'react-hook-form';
import Tooltip from '../Tooltip/Tooltip';

const FormInput = (props) => {
  const { field } = useController(props);
  const onSelectChange = (value) => {
    field.onChange(value);
    props.onChange?.(value.target.value);
  };

  // useEffect(() => {
  //   if (props.value) {
  //     field.onChange(props.value);
  //   }
  // }, [props.value]);

  return (
    <div className={'form__input'}>
      <div className={'label-wrap'}>
        {props.label && (
          <label htmlFor={props.id} className={props.errors ? 'label error' : 'label'}>
            {props.label}
            {props.currency ? ` ${props.currency}` : ''}
            {props.rules && props.rules.required ? <span className={'required'}>*</span> : ''}
          </label>
        )}
        {props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}
      </div>
      <div className={props.errors ? 'input-wrap error' : 'input-wrap'}>
        <input
          {...field}
          {...props}
          className={props.errors ? 'input error' : 'input'}
          type={props.type}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onFocus={props.onFocus}
          onChange={onSelectChange}
          autoComplete={props.autoComplete ? props.autoComplete : ''}
          value={props.value || (field.value === null ? '' : field.value)}
        />
        {props.errors && <p className={'errorName'}>{props.errors.message}</p>}
      </div>
    </div>
  );
};

export default FormInput;
