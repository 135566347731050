import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Button from '../../../components/ui/Button';
import './changePassword.scss';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import Page from '../../Page/Page';
import { notify } from '../../../utils/notify';
import { changePassword } from '../../../api/myProfile';
import PasswordInput from '../../../components/ui/PasswordInput/PasswordInput';
import { FormattedMessage, useIntl } from 'react-intl';
import { PASSWORD_INPUT_VALIDATION } from '../../../constants/validators';

const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const intl = useIntl();

  const changingTabTitle = () => {
    document.title = intl.formatMessage({
      id: 'menu.changePassword',
      defaultMessage: 'Change password',
    });
  };

  const new_password = watch('password');

  const cancelAction = (): void => {
    reset({ old_password: '', password: '', password_confirmation: '' });
  };

  const onSubmit = (data): void => {
    setIsLoading(true);
    changePassword(data)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          notify({
            type: 'success',
            message: res,
            timeOut: 3000,
          });
          setTimeout(() => {
            navigate('/finance');
          }, 500);
        }
      })
      .catch((error) => {
        if (error && error.response !== null) {
          setIsLoading(false);
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  useEffect(() => {
    changingTabTitle();
  }, []);

  return (
    <DashboardLayout>
      <Page title={<FormattedMessage id="changePassword.title" defaultMessage="Change password" />}>
        <div className='change-password__page'>
          <div className={'sub-title'}>
            <FormattedMessage
              id="changePassword.descr"
              defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            />
          </div>
          <div className="form-wrap">
            <form className={'form'} onSubmit={handleSubmit(onSubmit)}>
              <Container className={isLoading ? 'p-0 blur' : 'p-0'}>
                <Row className={'form__line'}>
                  <Col md={6} xs={12}>
                    <PasswordInput
                      name="old_password"
                      type="password"
                      label={
                        <FormattedMessage
                          id="changePassword.form.field.label.currentPassword"
                          defaultMessage="Current password"
                        />
                      }
                      control={control}
                      rules={{
                        required: (
                          <FormattedMessage
                            id="form.error.requiredFieldnoName"
                            defaultMessage="Field is required"
                          />
                        ),
                      }}
                      errors={errors.old_password}
                    />
                  </Col>
                </Row>
                <Row className={'form__line'}>
                  <Col md={6} xs={12}>
                    <PasswordInput
                      name="password"
                      type="password"
                      label={
                        <FormattedMessage
                          id="changePassword.form.field.label.newPassword"
                          defaultMessage="New password"
                        />
                      }
                      control={control}
                      rules={{
                        required: (
                          <FormattedMessage
                            id="form.error.requiredFieldnoName"
                            defaultMessage="Field is required"
                          />
                        ),
                        validate: PASSWORD_INPUT_VALIDATION,
                      }}
                      errors={errors.password}
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <PasswordInput
                      name="password_confirmation"
                      type="password"
                      label={
                        <FormattedMessage
                          id="changePassword.form.field.label.confirmPassword"
                          defaultMessage="Confirm password"
                        />
                      }
                      control={control}
                      rules={{
                        required: (
                          <FormattedMessage
                            id="form.error.requiredFieldnoName"
                            defaultMessage="Field is required"
                          />
                        ),
                        validate: {
                          ...PASSWORD_INPUT_VALIDATION,
                          compare: (value) =>
                            value === new_password || (
                              <FormattedMessage
                                id="form.error.passwordMessage_8"
                                defaultMessage="The password confirmation does not match."
                              />
                            ),
                        },
                      }}
                      errors={errors.password_confirmation}
                    />
                  </Col>
                </Row>
                <div className={'button-wrap'}>
                  <Button
                    type="button"
                    buttonText={
                      <FormattedMessage
                        id="changePassword.form.field.btn.reset"
                        defaultMessage="Reset"
                      />
                    }
                    buttonType="secondary"
                    onClick={() => cancelAction()}
                  />
                  <Button
                    type="submit"
                    buttonText={
                      <FormattedMessage
                        id="changePassword.form.field.btn.change"
                        defaultMessage="Change password"
                      />
                    }
                    disabled={isLoading}
                  />
                </div>
              </Container>
            </form>
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default ChangePassword;
