import { useEffect, useState } from "react";
import { getProhibitedCountryList } from "../api/country";

export const useGetProhibitedCountries = () => {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [iso, setIso] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await getProhibitedCountryList();
                if (res?.data) {
                    setList(res.data.countries);
                    setIso(res.data.isoCodes.map(c => c.toLowerCase()));
                }
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, []);

    return { list, iso, loading }
}