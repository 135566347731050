import React from 'react';
import './table.scss';
import { makeTableData } from '../../../../helpers/table.helper';
import Table from '../Table';
import {
  TABLE_OPENED_POSITIONS_COLUMNS_NAMES,
} from '../../../../constants/table';
import moment from 'moment';

const OpenedPositionsTable = ({
  data,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
  manualSortHandler,
  colSortCheck,
  onSort,
}) => {
  const columns = React.useMemo(() => TABLE_OPENED_POSITIONS_COLUMNS_NAMES, []);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const {
      current_price,
      open_price,
      opened_at,
      opened_at_dt,
      position,
      profit,
      symbol,
      volume,
      account,
      side,
      demo
    } = item;

    return {
      account: (
        <div className={'payments-table_td'}>
          {demo === 1 && <><span style={{ color: 'blue', fontWeight: 'bold' }}>DEMO</span>{' '}</>}
          <span>{account}</span>
        </div>
      ),
      side: (
        <div className={'payments-table_td'}>
          <span>{side}</span>
        </div>
      ),
      position: (
        <div className={'payments-table_td'}>
          <span>{position}</span>
        </div>
      ),
      symbol: (
        <div className={'payments-table_td'}>
          <span>{symbol}</span>
        </div>
      ),
      open_price: (
        <div className={'payments-table_td'}>
          <span>{open_price}</span>
        </div>
      ),
      current_price: (
        <div className={'payments-table_td'}>
          <span>{current_price}</span>
        </div>
      ),
      volume: (
        <div className={'payments-table_td'}>
          <span>{volume}</span>
        </div>
      ),
      profit: (
        <div className={'payments-table_td'}>
          <span>{profit}</span>
        </div>
      ),
      open_time: (
        <div className={'payments-table_td date-cell'}>
          <span>{moment(opened_at_dt, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')}</span>
          <span>{moment(opened_at_dt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')}</span>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  const checkSortEnable = (colId) => {
    switch (colId) {
      case 'original_agent':
      case 'country':
      case 'amount':
      case 'trading_acc':
      case 'date':
        return true;
      default:
        return false;
    }
  };

  return (
    <Table
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={'trading-table'}
      onSort={onSort}
      manualSortHandler={manualSortHandler}
      colSortCheck={colSortCheck || checkSortEnable}
      isSelected={false}
      getCellProps={(cellInfo) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : 'var(--body-background-color)'}`,
          },
        };
      }}
    />
  );
};

export default OpenedPositionsTable;
