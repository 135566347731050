import React from "react";
import { ChatResponseImage, MessageTypesVerbose } from "../../../../types/Chat";
import { renderDangerHTML } from "../RenderDangerHtml/RenderDangerHtml";
import { ImageBody } from "./ImageBody";
import { DocumentBody } from "./DocumentBody";

type ChatMessageBodyProps = {
    body: string;
    type: MessageTypesVerbose;
    image?: ChatResponseImage;
    document?: string;
    edited?: boolean;
};

export const ChatMessageBody = ({body, type, image, document, edited}: ChatMessageBodyProps) => {
    return (
        <div className="chat-message-body">
            {type === MessageTypesVerbose.MESSAGE ? (
                <div className="chat-message-text">
                    {renderDangerHTML(body, '', 'span')}
                    {edited ? <span className="edited">&nbsp;(edited)</span> : null}
                </div> )
                 : null}
            {type === MessageTypesVerbose.IMAGE_MESSAGE ? <ImageBody image={image} /> : null}
            {type === MessageTypesVerbose.DOCUMENT_MESSAGE ? <DocumentBody filename={body} document={document}/> : null}
        </div>
    )
}