import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import './personal.scss';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import Page from '../../Page/Page';
import { useForm } from 'react-hook-form';
import FormInput from '../../../components/ui/FormInput/FormInput';
import Button from '../../../components/ui/Button';
import Select from '../../../components/ui/Select/Select';
import { getLangList } from '../../../api/lang';
import { getMyInfo, updateMyInfo } from '../../../api/myProfile';
import getUTCtime from '../../../utils/getUTCTime';
import { notify } from '../../../utils/notify';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import CalendarPicker from '../../../components/ui/CalendarPicker';
import NumberInput from '../../../components/ui/NumberInput/NumberInput';

const Personal = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [langList, setLangList] = useState([]);
  const [formState, setFormState] = useState(null);
  const [id, setId] = useState(null);
  const [gender, setGender] = useState(null);
  const genderOpt = [
    {
      value: 'male',
      label: intl.formatMessage({
        id: 'personalInfo.form.field.label.male',
        defaultMessage: 'personalInfo.form.field.label.male',
      }),
    },
    {
      value: 'female',
      label: intl.formatMessage({
        id: 'personalInfo.form.field.label.female',
        defaultMessage: 'personalInfo.form.field.label.female',
      }),
    },
  ];

  const changingTabTitle = () => {
    const title = intl.formatMessage({
      id: 'personalInfo.title',
      defaultMessage: 'Personal info',
    });
    document.title = title;
  };

  const cancelAction = () => {
    reset({
      first_name: formState.profile.first_name === null ? '' : formState.profile.first_name,
      last_name: formState.profile.last_name === null ? '' : formState.profile.last_name,
      gender: {
        label: formState?.profile?.gender ? translateLanguageType(formState?.profile?.gender) : null,
        value: formState?.profile?.gender ? formState?.profile?.gender : null,
      },
      birthday: formState.profile.birthday !== null ? formState.profile.birthday * 1000 : null,
      country: formState.country === null ? null : formState.country,
      language_id: formState.language === null ? null : formState.language,
      city: formState.profile.city === null ? '' : formState.profile.city,
      address: formState.profile.address === null ? '' : formState.profile.address,
      postal_code: formState.profile.postal_code === null ? '' : formState.profile.postal_code,
      email: formState.email === null ? '' : formState.email,
      alt_email: formState.profile.alt_email === null ? '' : formState.profile.alt_email,
      phone: formState.phone === null ? '' : formState.phone,
      alt_phone: formState.profile.alt_phone === null ? '' : formState.profile.alt_phone,
      country_specific_identifier:
        formState.profile.country_specific_identifier === null
          ? ''
          : formState.profile.country_specific_identifier,
      ident_number: formState?.profile?.ident_number ? formState?.profile?.ident_number : null,
    });
  };

  const fetchLangList = () => {
    getLangList().then((res) => {
      setLangList(res.data.data);
    });
  };

  const translateLanguageType = (item) => {
    switch (item) {
      case 'male':
        return <FormattedMessage
          id="personalInfo.form.field.label.male"
          defaultMessage="personalInfo.form.field.label.male"
        />
      case 'female':
        return <FormattedMessage
          id="personalInfo.form.field.label.female"
          defaultMessage="personalInfo.form.field.label.female"
        />
      // default:
      //   return '';
    }
  };
  const fetchMyInfo = () => {
    getMyInfo().then((res) => {
      if (res) {
        const userData = res.data.data;
        setFormState(userData);
        setId(userData.profile.id)
        setGender(userData.profile.gender)
        reset({
          first_name: userData.profile.first_name,
          last_name: userData.profile.last_name,
          gender:
            userData.profile.gender !== null
              ? {
                label: translateLanguageType(userData.profile.gender),
                value: userData.profile.gender,
              }
              : null,
          birthday: userData.profile.birthday !== null ? userData.profile.birthday * 1000 : null,
          country: userData.country,
          language_id: userData.language,
          ident_number: userData.profile.ident_number,
          city: userData.profile.city,
          address: userData.profile.address,
          postal_code: userData.profile.postal_code,
          email: userData.email,
          alt_email: userData.profile.alt_email !== null ? userData.profile.alt_email : null,
          phone: userData.phone,
          alt_phone: userData.profile.alt_phone === null ? '' : userData.profile.alt_phone,
          country_specific_identifier: userData.profile.country_specific_identifier,
        });
      }
    });
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const sendData = {
      id: id,
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender ? data.gender.value : null,
      birthday: data.birthday !== null ? getUTCtime('startDay', data.birthday) / 1000 : null,
      country: data.country,
      language_id: data.language_id && data.language_id.id !== null ? data.language_id.id : null,
      ident_number: data.ident_number,
      city: data.city,
      address: data.address,
      postal_code: data.postal_code,
      email: data.email,
      alt_email: data.alt_email,
      phone: data.phone,
      alt_phone: data.alt_phone,
      country_specific_identifier: data.country_specific_identifier,
    };
    updateMyInfo(sendData)
      .then((res) => {
        if (res.status === 200) {
          fetchMyInfo();
          notify({
            type: 'success',
            message: res,
            timeOut: 3000,
          });
        }
      })
      .catch((error) => {
        if (error && error.response !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchMyInfo();
    fetchLangList();
    changingTabTitle();
  }, []);

  return (
    <DashboardLayout>
      <Page title={<FormattedMessage id="personalInfo.title" defaultMessage="Personal info" />}>
        <div className='personal__page'>
          <div className='sub-title'>
            <FormattedMessage
              id="personalInfo.descr"
              defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            />
          </div>
          <div className="form-wrap">
            <form className={'form'} onSubmit={handleSubmit(onSubmit)}>
              <Container className={isLoading ? 'p-0 blur' : 'p-0'}>
                <Row className={'form__line'}>
                  <Col md={6} xs={12}>
                    <FormInput
                      name="first_name"
                      type="text"
                      label={
                        <FormattedMessage
                          id="personalInfo.form.field.label.firstName"
                          defaultMessage="First name"
                        />
                      }
                      control={control}
                      rules={{ required: ' Field is required' }}
                      errors={errors.first_name}
                      disabled={formState && formState.first_name !== null ? true : false}
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <FormInput
                      name="last_name"
                      type="text"
                      label={
                        <FormattedMessage
                          id="personalInfo.form.field.label.lastName"
                          defaultMessage="Last name"
                        />
                      }
                      control={control}
                      rules={{ required: ' Field is required' }}
                      errors={errors.last_name}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className={'form__line'}>
                  <Col md={6} xs={12}>
                    <Select
                      name="gender"
                      label={
                        <FormattedMessage
                          id="personalInfo.form.field.label.gender"
                          defaultMessage="Gender"
                        />
                      }
                      value={translateLanguageType(gender)}
                      control={control}
                      options={genderOpt}
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <CalendarPicker
                      name="birthday"
                      control={control}
                      label={
                        <FormattedMessage
                          id="personalInfo.form.field.label.birth"
                          defaultMessage="Date of birth"
                        />
                      }
                      disabled={formState && formState.profile.birthday !== null ? true : false}
                    />
                  </Col>
                </Row>
                <Row className={'form__line'}>
                  <Col md={6} xs={12}>
                    <Select
                      name="language_id"
                      label={
                        <FormattedMessage
                          id="personalInfo.form.field.label.language"
                          defaultMessage="Language"
                        />
                      }
                      control={control}
                      options={langList}
                      menuIsOpen={true}
                      getOptionValue={(opt) => opt.id}
                      getOptionLabel={(opt) => opt.name}
                      // disabled={formState && formState.language !== null ? true : false}
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <FormInput
                      name="ident_number"
                      type="text"
                      label={
                        <FormattedMessage
                          id="personalInfo.form.field.label.identNumber"
                          defaultMessage="Identification Number"
                        />
                      }
                      control={control}
                    />
                  </Col>
                </Row>
                <Row className={'form__line'}>
                  <Col md={6} xs={12}>
                    <Select
                      name="country"
                      label={
                        <FormattedMessage
                          id="personalInfo.form.field.label.country"
                          defaultMessage="Country"
                        />
                      }
                      control={control}
                      options={langList}
                      menuIsOpen={true}
                      getOptionValue={(opt) => opt.id}
                      getOptionLabel={(opt) => opt.name}
                      disabled
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <FormInput
                      name="city"
                      type="text"
                      label={
                        <FormattedMessage
                          id="personalInfo.form.field.label.city"
                          defaultMessage="City"
                        />
                      }
                      control={control}
                      disabled={formState && formState.profile.city !== null ? true : false}
                    />
                  </Col>
                </Row>
                <Row className={'form__line'}>
                  <Col md={6} xs={12}>
                    <FormInput
                      name="address"
                      type="text"
                      label={
                        <FormattedMessage
                          id="personalInfo.form.field.label.address"
                          defaultMessage="Address"
                        />
                      }
                      control={control}
                      disabled={formState && formState.profile.address !== null ? true : false}
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <FormInput
                      name="postal_code"
                      type="text"
                      label={
                        <FormattedMessage
                          id="personalInfo.form.field.label.postalCode"
                          defaultMessage="Postal code"
                        />
                      }
                      control={control}
                      disabled={formState && formState.profile.postal_code !== null ? true : false}
                    />
                  </Col>
                </Row>
                <Row className={'form__line'}>
                  <Col md={6} xs={12}>
                    <FormInput
                      name="email"
                      type="text"
                      label={
                        <FormattedMessage
                          id="personalInfo.form.field.label.email"
                          defaultMessage="E-mail"
                        />
                      }
                      control={control}
                      rules={{
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: (
                            <FormattedMessage
                              id="form.error.email"
                              defaultMessage="Incorrect E-mail address"
                            />
                          ), // <p>error message</p>
                        },
                      }}
                      errors={errors.email}
                      disabled
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <FormInput
                      name="alt_email"
                      type="alt_email"
                      label={
                        <FormattedMessage
                          id="personalInfo.form.field.label.altEmail"
                          defaultMessage="Additional E-mail"
                        />
                      }
                      control={control}
                      rules={{
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: (
                            <FormattedMessage
                              id="form.error.email"
                              defaultMessage="Incorrect E-mail address"
                            />
                          ), // <p>error message</p>
                        },
                      }}
                      errors={errors.alt_email}
                      // disabled={formState && formState.profile.alt_email !== null ? true : false}
                    />
                  </Col>
                </Row>
                <Row className={'form__line'}>
                  <Col md={6} xs={12}>
                    <FormInput
                      name='phone'
                      type='text'
                      disabled={true}
                      label={
                        <FormattedMessage
                          id="personalInfo.form.field.label.phone"
                          defaultMessage="Phone"
                        />
                      }
                      control={control}
                    />
                    {/*<NumberInput*/}
                    {/*  name="phone"*/}
                    {/*  label={*/}
                    {/*    <FormattedMessage*/}
                    {/*      id="personalInfo.form.field.label.phone"*/}
                    {/*      defaultMessage="Phone"*/}
                    {/*    />*/}
                    {/*  }*/}
                    {/*  control={control}*/}
                    {/*  disabled={true}*/}
                    {/*  format="+# (###) ###-####"*/}
                    {/*  mask="_"*/}
                    {/*/>*/}
                  </Col>
                  <Col md={6} xs={12}>
                    <FormInput
                      rules={{
                        validate: {
                          correctPhone: (value) =>
                            /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,12}$/.test(
                              value) || !value || value.trim().length === 0 ||
                            'Enter a different phone format',
                        },
                      }}
                      errors={errors.alt_phone}
                      name='alt_phone'
                      type='text'
                      label={
                        <FormattedMessage
                          id="personalInfo.form.field.label.altPhone"
                          defaultMessage="Additional Phone"
                        />
                      }
                      maxLength='20'
                      control={control}
                    />
                    {/*<NumberInput*/}
                    {/*  name="alt_phone"*/}
                    {/*  label={*/}
                    {/*    <FormattedMessage*/}
                    {/*      id="personalInfo.form.field.label.altPhone"*/}
                    {/*      defaultMessage="Additional Phone"*/}
                    {/*    />*/}
                    {/*  }*/}
                    {/*  control={control}*/}
                    {/*  format="+# (###) ###-####"*/}
                    {/*  mask="_"*/}
                    {/*  rules={{*/}
                    {/*    validate: {*/}
                    {/*      minLength: (value) =>*/}
                    {/*        /^([+]?[0-9\s-\(\)]{3,25})*$/i.test(value) || (*/}
                    {/*          <FormattedMessage*/}
                    {/*            id="form.error.phone"*/}
                    {/*            defaultMessage="Incorrect Phone number"*/}
                    {/*          />*/}
                    {/*        ),*/}
                    {/*    },*/}
                    {/*  }}*/}
                    {/*  errors={errors.alt_phone}*/}
                    {/*/>*/}
                  </Col>
                </Row>
                <Row className={'form__line'}>
                  <Col md={6} xs={12}>
                    <FormInput
                      name="country_specific_identifier"
                      type="text"
                      label={
                        <FormattedMessage
                          id="personalInfo.form.field.label.countryIdent"
                          defaultMessage="Country specific identifier"
                        />
                      }
                      control={control}
                      // disabled={
                      //   formState && formState.profile.country_specific_identifier !== null
                      //     ? true
                      //     : false
                      // }
                    />
                  </Col>
                </Row>
                <div className={'button-wrap'}>
                  <Button
                    type="button"
                    buttonText={
                      <FormattedMessage
                        id="personalInfo.form.field.btn.reset"
                        defaultMessage="Reset"
                      />
                    }
                    buttonType="secondary"
                    onClick={() => cancelAction()}
                  />
                  <Button
                    type="submit"
                    buttonText={
                      <FormattedMessage
                        id="personalInfo.form.field.btn.update"
                        defaultMessage="Update profile"
                      />
                    }
                    disabled={isLoading}
                  />
                </div>
              </Container>
            </form>
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default Personal;
