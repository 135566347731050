import React, { useEffect, useState } from 'react';
import { languages } from '../languages';
import { IntlProvider } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setCurrentLang } from '../store/slice/langSlice';

export const Context = React.createContext();

const local = localStorage.getItem('lang') || 'en';

let lang = languages[local || 'en'];

const LangWrapper = (props) => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentLang(locale));
  }, [dispatch, locale]);
  
  const selectLang = (value) => {
    setLocale(value);

    localStorage.setItem('lang', value);

    setMessages(languages[value || 'en']);
  };

  return (
    <Context.Provider value={{ locale, selectLang }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default LangWrapper;
