import React, { useEffect, useRef, useState } from 'react';

import './finance.scss';
import { DashboardLayout } from '../UserView/DashboardLayout';
import ControlsGroup from '../../components/ui/ControlsGroup/ControlsGroup';
import ControlsGroupTitle from '../../components/ui/ControlsGroup/ControlsGroupTitle/ControlsGroupTitle';
import Button from '../../components/ui/Button/Button';
import Page from '../Page/Page';
import { POPUP_TYPE } from '../../constants/popup';
import AccountSelect from '../../components/ui/AccountSelect/AccountSelect';
import { getTradingAccounts } from '../../api/trading-account';
import FinancePopup from '../../components/ui/FinancePopup';
import { getHeaderInfo } from '../../api/myProfile';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderInfo } from '../../store/slice/headerInfoSlice';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { setNeedToUpdate } from '../../store/slice/transactionSlice';
import NewAccountPopup from '../../components/ui/NewAccountPopup/NewAccountPopup';
import { getTransactionUpdate } from '../../utils/selectors';
import { TRADING_ACCOUNT_STATUS } from 'constants/trading-account-status';
import { Spinner } from 'phosphor-react';

const Finance = () => {
  const [tradingAccounts, setTradingAccounts] = useState<any>(null);
  const [selectedDepositAcc, setDepositAcc] = useState();
  const [selectedWithdrawAcc, setWithdrawAcc] = useState(null);
  const [selectedTransferFromAcc, setTransferFromAcc] = useState(null);
  const [selectedTransferToAcc, setTransferToAcc] = useState(null);
  const [accountsFrom, setAccountsFrom] = useState([]);
  const [accountsTo, setAccountsTo] = useState<any>([]);
  const [loaded, setLoaded] = useState(false);
  const isNeedToUpdateTableData = useSelector(getTransactionUpdate);
  const componentMounted = useRef(true);
  const dispatch = useDispatch();
  const [taCount, setTaCount] = useState(null);
  const intl = useIntl();

  const changingTabTitle = (): void => {
    document.title = intl.formatMessage({
      id: 'menu.finance',
      defaultMessage: 'Finance',
    });
  };

  const getDefaultValue = () => {
    return {
      fromTransfer: '',
      toTransfer: '',
    };
  };

  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });

  useEffect(() => {
    getAccountsList();
    changingTabTitle();

    return (): void => {
      componentMounted.current = false;
    };
  }, []);

  useEffect((): void => {
    if (!isNeedToUpdateTableData.value) {
      return;
    }
    getAccountsList();
  }, [isNeedToUpdateTableData]);

  const resetDefaultValue = () => {
    resetField('fromTransfer')
    resetField('toTransfer')
  }


  useEffect((): void => {
    if (selectedTransferFromAcc) {
      const currentAccountsFilter = tradingAccounts.filter(item =>
        item.trading_account !== selectedTransferFromAcc.trading_account && item.amount_type
        === selectedTransferFromAcc.amount_type,
      )
      setAccountsTo(currentAccountsFilter);
    }

    if (selectedTransferToAcc) {
      setAccountsFrom(tradingAccounts.filter(item =>
        item.trading_account !== selectedTransferToAcc.trading_account && item.amount_type
        === selectedTransferToAcc.amount_type,
      ));
    }

    if (selectedTransferFromAcc && selectedTransferToAcc) {
      setAccountsFrom(tradingAccounts)
      resetField('toTransfer')
    }
  }, [selectedTransferFromAcc, selectedTransferToAcc]);

  const getAccountsList = (): void => {
    getTradingAccounts(`per_page=100&status=${TRADING_ACCOUNT_STATUS.ACTIVE}`)
      .then((res): void => {
        if (res && res.status === 200) {
          const accList = res.data.data;
          setTradingAccounts(accList);
          setAccountsFrom(accList);
          setAccountsTo(accList);
          setTaCount(accList.length);
          setLoaded(true);
        }
      })
      .catch((err) => console.log(err));
  };

  // const updateHeaderInfo = (): void => {
  //   getHeaderInfo()
  //     .then((res): void => {
  //       const info = res.data.data;
  //       dispatch(setHeaderInfo(info));
  //     })
  //     .catch((err): void => {
  //       console.log(err);
  //     });
  // };

  return (
    <DashboardLayout>
      <Page>
        {loaded ? (taCount !== 0 ? (
          <div className={'finance__page'}>
            <div className={'finance__page-row'}>
              <div className={'finance__page-col'}>
                <ControlsGroup className={'m-r'}>
                  <ControlsGroupTitle>
                    <FormattedMessage id='page.finance.deposit.title' defaultMessage='Deposit' />
                  </ControlsGroupTitle>
                  <AccountSelect
                    title={
                      <FormattedMessage
                        id='page.finance.deposit.field.toTradingAccount.title'
                        defaultMessage='To trading account'
                      />
                    }
                    placeholder={
                      <FormattedMessage id='placeholder.select' defaultMessage='Select' />
                    }
                    accountsData={tradingAccounts}
                    control={control}
                    name='toDeposit'
                    listHeight={300}
                    handleChange={(data): void => {
                      setDepositAcc(data.data);
                    }}
                    hideDemo
                  />
                  <FinancePopup
                    type={POPUP_TYPE.DEPOSIT}
                    trigger={
                      <Button
                        style={{ margin: 0 }}
                        buttonText={
                          <FormattedMessage
                            id='page.finance.deposit.btn'
                            defaultMessage='Deposit'
                          />
                        }
                        buttonType='outline'
                        buttonStyle='container-btn'
                        size='big'
                      />
                    }
                    onCloseModal={() => console.log('Finance scope - onCloseModal')}
                    onSuccess={(): void => {
                      // updateHeaderInfo();
                      dispatch(setNeedToUpdate({ value: true }));
                    }}
                    onError={() => console.log('Finance scope - onError')}
                    defaultToAccount={selectedDepositAcc}
                    accountsData={tradingAccounts}
                  />
                </ControlsGroup>
              </div>
              <div className={'finance__page-col'}>
                <ControlsGroup>
                  <ControlsGroupTitle>
                    <FormattedMessage
                      id='page.finance.transfer.title'
                      defaultMessage='Transfer funds'
                    />
                  </ControlsGroupTitle>
                  <AccountSelect
                    title={
                      <FormattedMessage
                        id='page.finance.transfer.field.fromTradingAccount.title'
                        defaultMessage='From trading account'
                      />
                    }
                    placeholder={
                      <FormattedMessage id='placeholder.select' defaultMessage='Select' />
                    }
                    accountsData={accountsFrom}
                    name='fromTransfer'
                    control={control}
                    listHeight={300}
                    handleChange={(data): void => {
                      setTransferFromAcc(data.data);
                    }}
                    hideDemo
                  />
                  <AccountSelect
                    title={
                      <FormattedMessage
                        id='page.finance.transfer.field.toTradingAccount.title'
                        defaultMessage='To trading account'
                      />
                    }
                    placeholder={
                      <FormattedMessage id='placeholder.select' defaultMessage='Select' />
                    }
                    accountsData={accountsTo}
                    name='toTransfer'
                    control={control}
                    listHeight={300}
                    handleChange={(data): void => {
                      setTransferToAcc(data.data);
                    }}
                    hideDemo
                  />
                  <FinancePopup
                    type={POPUP_TYPE.TRANSFER}
                    trigger={
                      <Button
                        style={{ margin: 0 }}
                        buttonText={
                          <FormattedMessage
                            id='page.finance.transfer.btn'
                            defaultMessage='Transfer funds'
                          />
                        }
                        buttonType='outline'
                        buttonStyle='container-btn'
                        size='big'
                      />
                    }
                    onCloseModal={() => console.log('Finance scope - onCloseModal')}
                    onSuccess={(): void => {
                      // updateHeaderInfo();
                      dispatch(setNeedToUpdate({ value: true }));
                    }}
                    resetDefaultValue={() => resetDefaultValue()}
                    onError={() => console.log('Finance scope - onError')}
                    defaultFromAccount={selectedTransferFromAcc}
                    defaultToAccount={selectedTransferToAcc}
                    accountsData={tradingAccounts}
                  />
                </ControlsGroup>
              </div>
              <div className={'finance__page-col'}>
                <ControlsGroup>
                  <ControlsGroupTitle>
                    <FormattedMessage id='page.finance.widthdraw.title' defaultMessage='Widthraw' />
                  </ControlsGroupTitle>
                  <AccountSelect
                    title={
                      <FormattedMessage
                        id='page.finance.widthdraw.fieldName'
                        defaultMessage='From trading account'
                      />
                    }
                    placeholder={
                      <FormattedMessage id='placeholder.select' defaultMessage='Select' />
                    }
                    accountsData={tradingAccounts}
                    control={control}
                    name='fromWithdraw'
                    listHeight={300}
                    handleChange={(data): void => {
                      setWithdrawAcc(data.data);
                    }}
                    hideDemo
                  />
                  <FinancePopup
                    type={POPUP_TYPE.WITHDRAW}
                    trigger={
                      <Button
                        style={{ margin: 0 }}
                        buttonText={
                          <FormattedMessage
                            id='page.finance.widthdraw.btn'
                            defaultMessage='Widthraw'
                          />
                        }
                        buttonType='outline'
                        buttonStyle='container-btn'
                        size='big'
                      />
                    }
                    onCloseModal={() => console.log('Finance scope - onCloseModal')}
                    onSuccess={(): void => {
                      // updateHeaderInfo();
                      dispatch(setNeedToUpdate({ value: true }));
                    }}
                    onError={() => console.log('Finance scope - onError')}
                    defaultToAccount={selectedWithdrawAcc}
                    accountsData={tradingAccounts}
                  />
                </ControlsGroup>
              </div>
            </div>
          </div>
        ) : (
          <div className={'infoBox'}>
            <p>{intl.formatMessage({ id: 'trading.accounts.firsttext' })}</p>
            <NewAccountPopup
              trigger={
                <Button
                  buttonText={
                    <FormattedMessage id='trading.accounts.btn' defaultMessage='+ New Account' />
                  }
                  size='big'
                />
              }
              onClose={(data): void => {
                if (data) {
                  getAccountsList();
                }
              }}
            />
          </div>
        )) : (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 'calc(100vh - 130px)' }}>
            <Spinner />
          </div>
        )}
      </Page>
    </DashboardLayout>
  );
};

export default Finance;
